import { Close } from '@mui/icons-material'
import clsx from 'clsx'
import { ReactNode, Ref } from 'react'

import { IconButton } from '../icon_buttons'
import { LayerCardIcon, LayerCardIconProps } from './LayerCardIcon'

export type LayerCardProps = {
  /**
   * Table title.
   */
  title: string
  /**
   * Title style.
   */
  titleStyle?: 'default' | 'header' | 'lean'
  /**
   * Optional subtitle.
   */
  subtitle?: string
  /**
   * Subtitle style.
   */
  subtitleStyle?: 'default' | 'brand'
  /**
   * Children to display.
   */
  children: ReactNode
  /**
   * Optionally add close button by adding a close handler.
   */
  onClose?: () => void
  /**
   * Optional container ref.
   */
  containerRef?: Ref<HTMLDivElement>
  /**
   * Optional class name.
   */
  className?: string
} & Partial<Pick<LayerCardIconProps, 'Icon'>>

export const LayerCard = ({
  title,
  titleStyle = 'default',
  subtitle,
  subtitleStyle = 'default',
  Icon,
  children,
  onClose,
  containerRef,
  className,
}: LayerCardProps) => {
  return (
    <div
      className={clsx(
        'relative bg-background-tertiary border border-border-secondary flex flex-col gap-4 rounded-md p-2 pt-3 max-w-full',
        className
      )}
      ref={containerRef}
    >
      <div
        className={clsx('flex flex-row gap-2 items-center', onClose && 'pr-10')}
      >
        {Icon && (
          <LayerCardIcon
            Icon={Icon}
            style={
              titleStyle === 'lean' ? 'ghost' : subtitle ? 'card' : 'card-sm'
            }
          />
        )}

        <div className="flex flex-col">
          <p
            className={clsx({
              'title-text': titleStyle === 'default',
              'header-text': titleStyle === 'header',
              'primary-text': titleStyle === 'lean',
            })}
          >
            {title}
          </p>
          <p
            className={clsx(
              'caption-text',
              subtitleStyle === 'brand' && 'text-text-brand'
            )}
          >
            {subtitle}
          </p>
        </div>
      </div>

      {onClose && (
        <IconButton
          Icon={Close}
          circular
          className="absolute top-1.5 right-1.5"
          onClick={onClose}
          size="sm"
          variant="ghost"
        />
      )}

      {children}
    </div>
  )
}
