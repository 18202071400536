import { Chain, IBCInfo } from '@chain-registry/types'
import {
  assets as chainRegistryAssets,
  chains as chainRegistryChains,
  ibc as chainRegistryIbc,
} from 'chain-registry'

import {
  AnyChain,
  BaseChainConfig,
  ChainId,
  CodeHashConfig,
  CodeIdConfig,
  ContractVersion,
  PolytoneConfig,
  SkipChain,
  SupportedChainConfig,
} from '@dao-dao/types'

import _ALL_CODE_HASHES from './codeHashes.json'
import _ALL_CODE_IDS from './codeIds.json'
import { ACTIVE_LAYER_CHAIN_ID } from './env'
import _ALL_POLYTONE from './polytone.json'

const ALL_CODE_HASHES = _ALL_CODE_HASHES as unknown as Partial<
  Record<ChainId, Partial<Record<ContractVersion, CodeHashConfig>>>
>
const ALL_CODE_IDS = _ALL_CODE_IDS as unknown as Partial<
  Record<ChainId, Partial<Record<ContractVersion, CodeIdConfig>>>
>
const ALL_POLYTONE = _ALL_POLYTONE as unknown as Partial<
  Record<ChainId, PolytoneConfig>
>

export const convertChainRegistryChainToAnyChain = (
  chain: Chain
): AnyChain => ({
  chainId: chain.chain_id,
  chainName: chain.chain_name,
  bech32Prefix: chain.bech32_prefix,
  prettyName: chain.pretty_name ?? chain.chain_name,
  chainRegistry: chain,
})

export const convertSkipChainToAnyChain = (chain: SkipChain): AnyChain => ({
  chainId: chain.chain_id,
  chainName: chain.chain_name,
  bech32Prefix: chain.bech32_prefix,
  prettyName: chain.pretty_name ?? chain.chain_name,
  skipChain: chain,
})

//! ----- Modified chain-registry -----
let chains: AnyChain[] = chainRegistryChains.map(
  convertChainRegistryChainToAnyChain
)
const assets = [...chainRegistryAssets]

// Layer
const LAYER_LOCALNET: Chain = {
  chain_name: ChainId.LayerLocal,
  chain_type: 'cosmos',
  status: 'live',
  network_type: 'testnet',
  website: 'https://github.com/Lay3rLabs',
  pretty_name: 'Slay3r',
  chain_id: ChainId.LayerLocal,
  bech32_prefix: 'layer',
  daemon_name: 'wasmd',
  key_algos: ['secp256k1'],
  slip44: 118,
  fees: {
    fee_tokens: [
      {
        denom: 'uslay',
        fixed_min_gas_price: 0.075,
        low_gas_price: 0.075,
        average_gas_price: 0.1,
        high_gas_price: 0.125,
      },
    ],
  },
  staking: {
    staking_tokens: [
      {
        denom: 'uslay',
      },
    ],
  },
  logo_URIs: {
    jpeg: '/chains/slay3r.jpeg',
  },
  description: 'Slay.',
  apis: {
    rpc: [
      {
        address: 'http://localhost:26657',
        provider: 'local',
      },
    ],
    rest: [
      {
        address: 'http://localhost:1317',
        provider: 'local',
      },
    ],
    grpc: [
      {
        address: 'http://localhost:9090',
        provider: 'local',
      },
    ],
  },
}
const LAYER_DEMONET: Chain = {
  chain_name: ChainId.LayerDemo,
  chain_type: 'cosmos',
  status: 'live',
  network_type: 'testnet',
  website: 'https://github.com/Lay3rLabs',
  pretty_name: 'Layer Demo',
  chain_id: ChainId.LayerDemo,
  bech32_prefix: 'layer',
  daemon_name: 'wasmd',
  key_algos: ['secp256k1'],
  slip44: 118,
  fees: {
    fee_tokens: [
      {
        denom: 'ulayer',
        fixed_min_gas_price: 0.075,
        low_gas_price: 0.075,
        average_gas_price: 0.1,
        high_gas_price: 0.125,
      },
    ],
  },
  staking: {
    staking_tokens: [
      {
        denom: 'ulayer',
      },
    ],
  },
  logo_URIs: {
    jpeg: '/chains/slay3r.jpeg',
  },
  description: 'Lay.',
  apis: {
    rpc: [
      {
        address: 'https://rpc.demo.layer.xyz',
        provider: 'Layer',
      },
    ],
    rest: [
      {
        address: 'https://api.demo.layer.xyz',
        provider: 'Layer',
      },
    ],
    grpc: [
      {
        address: 'https://grpc.demo.layer.xyz',
        provider: 'Layer',
      },
    ],
  },
}

switch (ACTIVE_LAYER_CHAIN_ID) {
  case ChainId.LayerLocal: {
    const layerChain = convertChainRegistryChainToAnyChain(LAYER_LOCALNET)
    chains.push(layerChain)
    assets.push({
      chain_name: layerChain.chainName,
      assets: [
        {
          description: 'Slay.',
          denom_units: [
            {
              denom: 'uslay',
              exponent: 0,
            },
            {
              denom: 'SLAY',
              exponent: 6,
            },
          ],
          base: 'uslay',
          name: 'Slay3r',
          display: 'SLAY',
          symbol: 'SLAY',
          logo_URIs: {
            jpeg: '/chains/slay3r.jpeg',
          },
          images: [
            {
              jpeg: '/chains/slay3r.jpeg',
            },
          ],
        },
      ],
    })
    break
  }
  case ChainId.LayerDemo: {
    const layerChain = convertChainRegistryChainToAnyChain(LAYER_DEMONET)
    chains.push(layerChain)
    assets.push({
      chain_name: layerChain.chainName,
      assets: [
        {
          description: 'Lay.',
          denom_units: [
            {
              denom: 'ulayer',
              exponent: 0,
            },
            {
              denom: 'LAYER',
              exponent: 6,
            },
          ],
          base: 'ulayer',
          name: 'Layer',
          display: 'LAYER',
          symbol: 'LAYER',
          logo_URIs: {
            jpeg: '/chains/slay3r.jpeg',
          },
          images: [
            {
              jpeg: '/chains/slay3r.jpeg',
            },
          ],
        },
      ],
    })
    break
  }
}

// Remove thorchain and althea since they spam the console.
const chainsToRemove = ['thorchain', 'althea']
chains = chains.filter((chain) => !chainsToRemove.includes(chain.chainName))

export { chains, assets }
//! ----- Modified chain-registry -----

export const ibc: IBCInfo[] = [...chainRegistryIbc]

/**
 * Chains where DAO DAO is deployed.
 */
const BASE_SUPPORTED_CHAINS: Omit<
  SupportedChainConfig,
  'codeIds' | 'allCodeIds' | 'codeHashes' | 'allCodeHashes' | 'polytone'
>[] = [
  ...(ACTIVE_LAYER_CHAIN_ID === ChainId.LayerLocal
    ? [
        {
          chainId: ChainId.LayerLocal,
          name: 'slay3r',
          mainnet: false,
          accentColor: '#c53381',
          noIndexer: true,
          factoryContractAddress:
            'layer14h7u4z77uhf6de9908pm6s3c6gld20fznsa026hcfdjkmfjf4g6s37k5es',
          tokenDaoType: 'both',
          latestVersion: ContractVersion.V250,
          wasmaticUrl: 'http://localhost:8081',
          faucetUrl: 'http://localhost:8000',
        } as const,
      ]
    : []),
  ...(ACTIVE_LAYER_CHAIN_ID === ChainId.LayerDemo
    ? [
        {
          chainId: ChainId.LayerDemo,
          name: 'layer',
          mainnet: false,
          accentColor: '#c53381',
          noIndexer: true,
          factoryContractAddress:
            'layer1hmnpga7d2cqpl94ls59sqcjrrmrp2z9fgy4hucllj9ygjd20d2nqswn8cx',
          tokenDaoType: 'both',
          latestVersion: ContractVersion.V250,
          wasmaticUrl: 'https://op3.demo.layer.xyz',
          faucetUrl: 'https://faucet.demo.layer.xyz',
        } as const,
      ]
    : []),
]

// Extract info from JSON config.
export const SUPPORTED_CHAINS: SupportedChainConfig[] =
  BASE_SUPPORTED_CHAINS.map((chain): SupportedChainConfig => {
    // Type-check to ensure chain code IDs are present in JSON.
    const allCodeIds = ALL_CODE_IDS[chain.chainId]
    if (!allCodeIds) {
      throw new Error(`No code IDs found for chain ${chain.chainId}`)
    }

    // Type-check to ensure correct version of code IDs are present in JSON.
    const codeIds = allCodeIds[chain.latestVersion]
    if (!codeIds) {
      throw new Error(
        `Version ${chain.latestVersion} code IDs not found for chain ${chain.chainId}`
      )
    }

    return {
      ...chain,
      codeIds,
      allCodeIds,
      codeHashes: ALL_CODE_HASHES[chain.chainId]?.[chain.latestVersion],
      allCodeHashes: ALL_CODE_HASHES[chain.chainId],
      polytone: ALL_POLYTONE[chain.chainId],
    }
  })

export const POLYTONE_CONFIG_PER_CHAIN: [ChainId, PolytoneConfig][] =
  SUPPORTED_CHAINS.map(({ chainId, polytone: polytone = {} }) => [
    chainId as ChainId,
    polytone,
  ])

export const VALENCE_SUPPORTED_CHAINS = SUPPORTED_CHAINS.filter(
  ({ valence }) => valence
).map(({ chainId }) => chainId as ChainId)

export const CHAIN_ENDPOINTS: Partial<
  Record<
    ChainId,
    {
      rpc: string
      rest: string
    }
  >
> = {
  [ChainId.LayerLocal]: {
    rpc: 'http://localhost:26657',
    rest: 'http://localhost:1317',
  },
  [ChainId.LayerDemo]: {
    rpc: 'https://rpc.demo.layer.xyz',
    rest: 'https://api.demo.layer.xyz',
  },
}

export const GAS_OVERRIDES: Partial<
  Record<
    ChainId,
    {
      amount: number
      denom: string
    }
  >
> = {}

/**
 * All configured chains. Configured chains are either supported chains, which
 * DAO DAO is deployed on, or other chains that show up in the governance UI.
 */
export const CONFIGURED_CHAINS: BaseChainConfig[] = [...SUPPORTED_CHAINS]
