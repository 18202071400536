import { AccountBalance, Add, Payments } from '@mui/icons-material'
import { ComponentType, useState } from 'react'

import {
  StatefulAvsCommitmentsProps,
  StatefulNewCommitmentCardProps,
} from '@dao-dao/types'

import { Button, LayerBadge, LayerCardIcon, LayerInfoList } from '../components'

export type VaultPageProps = {
  /**
   * The user's vault value.
   */
  vaultValue: string
  /**
   * Stateful new commitment card.
   */
  NewCommitmentCard: ComponentType<StatefulNewCommitmentCardProps>
  /**
   * Stateful commitments card.
   */
  AvsCommitments: ComponentType<StatefulAvsCommitmentsProps>
}

export const VaultPage = ({
  vaultValue,
  NewCommitmentCard,
  AvsCommitments,
}: VaultPageProps) => {
  const [showingNewCommitment, setShowingNewCommitment] = useState(false)

  return (
    <div className="flex flex-col gap-10 md:flex-row md:items-start">
      <div className="flex flex-col gap-6 min-w-80 shrink-0">
        <LayerCardIcon Icon={AccountBalance} style="header" />

        <div className="flex flex-col gap-1">
          <p className="hero-text">${vaultValue}</p>
          <p className="secondary-text">USD value committed</p>
        </div>

        <LayerInfoList
          items={[
            {
              label: 'Commitments',
              value: '4',
            },
            {
              label: 'APR',
              value: '~16.29%',
            },
            {
              label: 'Slashing',
              tooltip: 'The percent of your vault at risk of being slashed.',
              value: '5.00%',
              valueClassName: 'text-text-interactive-error',
            },
            {
              label: 'Yield',
              value: (
                <LayerBadge Icon={Payments} label="$84.43" style="valid" />
              ),
            },
          ]}
        />

        <div className="flex flex-col gap-2">
          <Button
            center
            className="animate-fade-in"
            size="lg"
            variant="secondary"
          >
            Claim rewards
          </Button>

          {showingNewCommitment ? (
            <NewCommitmentCard
              className="animate-fade-in"
              onClose={() => setShowingNewCommitment(false)}
            />
          ) : (
            <Button
              center
              className="animate-fade-in"
              onClick={() => setShowingNewCommitment(true)}
              size="lg"
              variant="secondary"
            >
              <Add className="!w-6 !h-6" /> New commitment
            </Button>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-6 grow min-w-0">
        <AvsCommitments />
      </div>
    </div>
  )
}
