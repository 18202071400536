import { Refresh, Send, SmartToy } from '@mui/icons-material'
import clsx from 'clsx'
import { useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import { AiBouncerForm, StatelessAiBouncerProps } from '@dao-dao/types'

import { IconButton } from '../icon_buttons'
import { TextInput } from '../inputs'
import { LayerCard } from '../layer'
import { Loader } from '../logo'

export const AiBouncer = ({
  messages,
  onSend,
  onRetry,
  loading,
  isWalletConnected,
  ConnectWallet,
  className,
}: StatelessAiBouncerProps) => {
  const { register, handleSubmit } = useFormContext<AiBouncerForm>()

  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const messagesLength = messages.length
  // Scroll to the bottom when a message is added.
  useEffect(() => {
    scrollContainerRef.current?.scrollTo({
      top: scrollContainerRef.current?.scrollHeight,
      behavior: 'smooth',
    })
  }, [messagesLength])

  const retrying =
    messages.length > 0 &&
    messages[messages.length - 1].source === 'user' &&
    messages[messages.length - 1].status === 'expired'

  return (
    <LayerCard
      Icon={SmartToy}
      className={clsx('h-[min(30rem,70dvh)]', className)}
      subtitle="AI Agent"
      subtitleStyle="brand"
      title="Bouncer"
    >
      <div className="grow border-border-secondary bg-background-secondary flex flex-col rounded-md border p-2 pt-0 overflow-hidden">
        <div
          className="styled-scrollbar grow flex flex-col gap-4 rounded-md px-2 pt-4 pb-3 overflow-y-scroll"
          ref={scrollContainerRef}
        >
          {messages.map(({ source, message }, index) => (
            <div
              key={index}
              className={clsx(
                'flex flex-row gap-2 items-center max-w-[80%]',
                source === 'ai'
                  ? 'self-start justify-start animate-enter-from-left'
                  : 'self-end justify-end px-2 py-1 animate-enter-from-right'
              )}
            >
              <p
                className={clsx(
                  'primary-text break-words text-base',
                  source === 'ai'
                    ? 'font-mono text-left text-text-secondary'
                    : 'text-right text-text-brand-secondary',
                  retrying &&
                    index === messages.length - 1 &&
                    'text-text-interactive-error'
                )}
              >
                {message}
              </p>

              {retrying && index === messages.length - 1 && (
                <IconButton
                  Icon={Refresh}
                  circular
                  iconClassName={clsx(
                    loading === 'sending' && 'animate-spin-medium'
                  )}
                  onClick={onRetry}
                  variant="ghost"
                />
              )}
            </div>
          ))}

          {loading === 'thinking' && (
            <Loader className="self-start" fill={false} size={24} />
          )}
        </div>

        <form
          className="shrink-0 flex flex-row gap-4 items-center rounded-md p-2 pl-3 ring-1 ring-border-primary focus-within:ring-2 focus-within:ring-border-interactive-focus"
          onSubmit={handleSubmit(onSend)}
        >
          <TextInput
            className="text-base !w-auto grow"
            disabled={!!loading || retrying}
            fieldName="message"
            ghost
            placeholder="Send a message..."
            register={register}
            required
          />

          {isWalletConnected ? (
            <IconButton
              Icon={Send}
              disabled={!!loading || retrying}
              loading={loading === 'sending' && !retrying}
              type="submit"
              variant="brand"
            />
          ) : (
            <ConnectWallet className="shrink-0 self-stretch" size="md" />
          )}
        </form>
      </div>
    </LayerCard>
  )
}
