import { FiberSmartRecord } from '@mui/icons-material'
import { uniq } from 'lodash'
import { useState } from 'react'

import { HugeDecimal } from '@dao-dao/math'
import { StatelessAvsCommitmentsProps } from '@dao-dao/types'
import { getFallbackImage, serializeTokenSource } from '@dao-dao/utils'

import { ErrorPage } from '../error'
import { AvsDisplay, OperatorDisplay, TokenDisplay } from '../ImageTextDisplay'
import { LayerCard, LayerTable } from '../layer'
import { Modal } from '../modals'
import { Sankey, SankeyData, SankeyDataLink, SankeyDataNode } from '../Sankey'
import { TokenAmountDisplay } from '../token'
import { CommitmentInfoCard } from './CommitmentInfoCard'

export const AvsCommitments = ({
  commitments,
  className,
}: StatelessAvsCommitmentsProps) => {
  const [selectedCommitmentId, setSelectedCommitmentId] = useState<string>()
  const [commitmentModalVisible, setCommitmentModalVisible] = useState(false)

  const commitmentsList =
    commitments.loading || commitments.errored ? [] : commitments.data
  const selectedCommitment = selectedCommitmentId
    ? commitmentsList.find(({ id }) => id === selectedCommitmentId)
    : undefined

  const uniqueAssets = uniq(
    commitmentsList.map(({ token }) => serializeTokenSource(token))
  ).map((source): SankeyDataNode => {
    const allCommitments = commitmentsList.filter(
      ({ token }) => serializeTokenSource(token) === source
    )
    const token = allCommitments[0]!.token
    const total = allCommitments.reduce((acc, { value }) => acc + value, 0)

    return {
      id: `asset-${source}`,
      label: token.symbol,
      imageUrl: token.imageUrl || getFallbackImage(token.denomOrAddress),
      caption:
        '$' +
        HugeDecimal.fromHumanReadable(
          total,
          2
        ).toInternationalizedHumanReadableString({
          decimals: 2,
          showFullAmount: false,
        }),
    }
  })
  const uniqueServices = uniq(commitmentsList.map(({ app }) => app.name)).map(
    (name): SankeyDataNode => {
      const allCommitments = commitmentsList.filter(
        ({ app }) => app.name === name
      )
      const app = allCommitments[0]!.app
      const total = allCommitments.reduce((acc, { value }) => acc + value, 0)

      return {
        id: `service-${name}`,
        label: name,
        imageUrl: app.imageUrl || getFallbackImage(app.name),
        caption:
          '$' +
          HugeDecimal.fromHumanReadable(
            total,
            2
          ).toInternationalizedHumanReadableString({
            decimals: 2,
            showFullAmount: false,
          }),
      }
    }
  )
  const allLinks = commitmentsList.map(
    ({ id, app, token, value }): SankeyDataLink => {
      const source = `asset-${serializeTokenSource(token)}`
      const target = `service-${app.name}`

      return {
        id,
        source,
        target,
        value: Math.log(value),
      }
    }
  )

  const sankeyData: SankeyData = {
    nodes: [...uniqueAssets, ...uniqueServices],
    links: allLinks,
  }

  const [hoverId, setHoverId] = useState<string>()

  return (
    <LayerCard
      Icon={FiberSmartRecord}
      className={className}
      title="Commitments"
      titleStyle="lean"
    >
      {commitments.errored ? (
        <ErrorPage error={commitments.error} />
      ) : (
        <>
          <Sankey
            className="my-4 mx-8"
            data={sankeyData}
            header={{
              source: 'Asset',
              target: 'AVS',
            }}
            highlightLinkId={hoverId}
            onClick={(link) => {
              setSelectedCommitmentId(link.id)
              setCommitmentModalVisible(true)
            }}
            onHover={(link) => setHoverId(link?.id)}
          />

          <LayerTable
            columns={[
              {
                label: 'AVS',
              },
              {
                label: 'Asset',
              },
              {
                label: 'Value',
                className: 'text-text-tertiary',
              },
              {
                label: 'Operator',
              },
            ]}
            onHover={(row) => setHoverId(row?.id)}
            rows={
              commitments.loading
                ? [...Array(4)].map((_, index) => ({
                    id: index.toString(),
                    loading: true,
                    values: [],
                  }))
                : commitments.data.map((commitment) => {
                    const { id, app, token, value, operator } = commitment
                    return {
                      id,
                      values: [
                        <AvsDisplay key="app" app={app} />,
                        <TokenDisplay key="token" token={token} />,
                        <TokenAmountDisplay
                          key="value"
                          amount={value}
                          estimatedUsdValue
                          hideSymbol
                        />,
                        <OperatorDisplay key="operator" operator={operator} />,
                      ],
                      onClick: () => {
                        setSelectedCommitmentId(commitment.id)
                        setCommitmentModalVisible(true)
                      },
                      active: hoverId === id,
                    }
                  })
            }
          />

          <Modal
            containerClassName="min-w-[min(22rem,96dvw)]"
            ghost
            onClose={() => setCommitmentModalVisible(false)}
            onClosed={() => setSelectedCommitmentId(undefined)}
            visible={commitmentModalVisible && !!selectedCommitment}
          >
            {selectedCommitment && (
              <CommitmentInfoCard
                commitment={selectedCommitment}
                linkIndex={commitmentsList.indexOf(selectedCommitment)}
                sankeyData={sankeyData}
              />
            )}
          </Modal>
        </>
      )}
    </LayerCard>
  )
}
