import { useFormContext } from 'react-hook-form'

import {
  AddressInput,
  InputErrorMessage,
  InputLabel,
  TextAreaInput,
  useChain,
} from '@dao-dao/stateless'
import { WidgetEditorProps } from '@dao-dao/types'
import { makeValidateAddress, validateRequired } from '@dao-dao/utils'

import { AiBouncerData } from './types'

export const Editor = ({
  fieldNamePrefix,
  isCreating,
  errors,
}: WidgetEditorProps<AiBouncerData>) => {
  const { bech32Prefix } = useChain()
  const { register } = useFormContext<AiBouncerData>()

  return (
    <>
      <div className="flex flex-col gap-1">
        <InputLabel name="Caller" />
        <AddressInput
          disabled={!isCreating}
          error={errors?.caller}
          fieldName={(fieldNamePrefix + 'caller') as 'caller'}
          register={register}
          type="contract"
          validation={[validateRequired, makeValidateAddress(bech32Prefix)]}
        />
        <InputErrorMessage error={errors?.caller} />
      </div>

      <div className="flex flex-col gap-1">
        <InputLabel name="Requirements" />
        <TextAreaInput
          disabled={!isCreating}
          error={errors?.requirements}
          fieldName={(fieldNamePrefix + 'requirements') as 'requirements'}
          register={register}
          validation={[validateRequired]}
        />
        <InputErrorMessage error={errors?.requirements} />
      </div>
    </>
  )
}
