import { UseQueryOptions } from '@tanstack/react-query'

import {
  ConfigResponse,
  HooksResponse,
  ListCompletedResponse,
  ListOpenResponse,
  ListResponse,
  TaskHookType,
  TaskId,
  TaskResponse,
  TaskStatusResponse,
} from '@dao-dao/types/contracts/LavsTaskQueue'
import { getCosmWasmClientForChainId } from '@dao-dao/utils'

import { LavsTaskQueueQueryClient } from '../../../contracts/LavsTaskQueue'

export const lavsTaskQueueQueryKeys = {
  contract: [
    {
      contract: 'lavsTaskQueue',
    },
  ] as const,
  address: (chainId: string, contractAddress: string) =>
    [
      {
        ...lavsTaskQueueQueryKeys.contract[0],
        chainId,
        address: contractAddress,
      },
    ] as const,
  taskStatus: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...lavsTaskQueueQueryKeys.address(chainId, contractAddress)[0],
        method: 'task_status',
        args,
      },
    ] as const,
  list: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...lavsTaskQueueQueryKeys.address(chainId, contractAddress)[0],
        method: 'list',
        args,
      },
    ] as const,
  listOpen: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...lavsTaskQueueQueryKeys.address(chainId, contractAddress)[0],
        method: 'list_open',
        args,
      },
    ] as const,
  listCompleted: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...lavsTaskQueueQueryKeys.address(chainId, contractAddress)[0],
        method: 'list_completed',
        args,
      },
    ] as const,
  task: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...lavsTaskQueueQueryKeys.address(chainId, contractAddress)[0],
        method: 'task',
        args,
      },
    ] as const,
  config: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...lavsTaskQueueQueryKeys.address(chainId, contractAddress)[0],
        method: 'config',
        args,
      },
    ] as const,
  name: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...lavsTaskQueueQueryKeys.address(chainId, contractAddress)[0],
        method: 'name',
        args,
      },
    ] as const,
  description: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...lavsTaskQueueQueryKeys.address(chainId, contractAddress)[0],
        method: 'description',
        args,
      },
    ] as const,
  taskHooks: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...lavsTaskQueueQueryKeys.address(chainId, contractAddress)[0],
        method: 'task_hooks',
        args,
      },
    ] as const,
}
export const lavsTaskQueueQueries = {
  taskStatus: <TData = TaskStatusResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: LavsTaskQueueTaskStatusQuery<TData>): UseQueryOptions<
    TaskStatusResponse,
    Error,
    TData
  > => ({
    queryKey: lavsTaskQueueQueryKeys.taskStatus(chainId, contractAddress, args),
    queryFn: async () => {
      return new LavsTaskQueueQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).taskStatus({
        id: args.id,
      })
    },
    ...options,
  }),
  list: <TData = ListResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: LavsTaskQueueListQuery<TData>): UseQueryOptions<
    ListResponse,
    Error,
    TData
  > => ({
    queryKey: lavsTaskQueueQueryKeys.list(chainId, contractAddress, args),
    queryFn: async () => {
      return new LavsTaskQueueQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).list({
        startAfter: args.startAfter,
        limit: args.limit,
      })
    },
    ...options,
  }),
  listOpen: <TData = ListOpenResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: LavsTaskQueueListOpenQuery<TData>): UseQueryOptions<
    ListOpenResponse,
    Error,
    TData
  > => ({
    queryKey: lavsTaskQueueQueryKeys.listOpen(chainId, contractAddress, args),
    queryFn: async () => {
      return new LavsTaskQueueQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).listOpen({
        startAfter: args.startAfter,
        limit: args.limit,
      })
    },
    ...options,
  }),
  listCompleted: <TData = ListCompletedResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: LavsTaskQueueListCompletedQuery<TData>): UseQueryOptions<
    ListCompletedResponse,
    Error,
    TData
  > => ({
    queryKey: lavsTaskQueueQueryKeys.listCompleted(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      return new LavsTaskQueueQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).listCompleted({
        startAfter: args.startAfter,
        limit: args.limit,
      })
    },
    ...options,
  }),
  task: <TData = TaskResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: LavsTaskQueueTaskQuery<TData>): UseQueryOptions<
    TaskResponse,
    Error,
    TData
  > => ({
    queryKey: lavsTaskQueueQueryKeys.task(chainId, contractAddress, args),
    queryFn: async () => {
      return new LavsTaskQueueQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).task({
        id: args.id,
      })
    },
    ...options,
  }),
  config: <TData = ConfigResponse>({
    chainId,
    contractAddress,
    options,
  }: LavsTaskQueueConfigQuery<TData>): UseQueryOptions<
    ConfigResponse,
    Error,
    TData
  > => ({
    queryKey: lavsTaskQueueQueryKeys.config(chainId, contractAddress),
    queryFn: async () => {
      return new LavsTaskQueueQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).config()
    },
    ...options,
  }),
  taskHooks: <TData = HooksResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: LavsTaskQueueTaskHooksQuery<TData>): UseQueryOptions<
    HooksResponse,
    Error,
    TData
  > => ({
    queryKey: lavsTaskQueueQueryKeys.taskHooks(chainId, contractAddress, args),
    queryFn: async () => {
      return new LavsTaskQueueQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).taskHooks({
        type: args.type,
      })
    },
    ...options,
  }),
}
export interface LavsTaskQueueReactQuery<TResponse, TData = TResponse> {
  chainId: string
  contractAddress: string
  options?: Omit<
    UseQueryOptions<TResponse, Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: undefined
  }
}
export interface LavsTaskQueueTaskHooksQuery<TData>
  extends LavsTaskQueueReactQuery<HooksResponse, TData> {
  args: {
    type: TaskHookType
  }
}
export interface LavsTaskQueueConfigQuery<TData>
  extends LavsTaskQueueReactQuery<ConfigResponse, TData> {}
export interface LavsTaskQueueTaskQuery<TData>
  extends LavsTaskQueueReactQuery<TaskResponse, TData> {
  args: {
    id: TaskId
  }
}
export interface LavsTaskQueueListCompletedQuery<TData>
  extends LavsTaskQueueReactQuery<ListCompletedResponse, TData> {
  args: {
    startAfter?: TaskId
    limit?: number
  }
}
export interface LavsTaskQueueListOpenQuery<TData>
  extends LavsTaskQueueReactQuery<ListOpenResponse, TData> {
  args: {
    startAfter?: TaskId
    limit?: number
  }
}
export interface LavsTaskQueueListQuery<TData>
  extends LavsTaskQueueReactQuery<ListResponse, TData> {
  args: {
    startAfter?: TaskId
    limit?: number
  }
}
export interface LavsTaskQueueTaskStatusQuery<TData>
  extends LavsTaskQueueReactQuery<TaskStatusResponse, TData> {
  args: {
    id: TaskId
  }
}
