import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'

import { wasmaticQueries } from '@dao-dao/state/query'
import { avsCommitmentsAtom } from '@dao-dao/state/recoil'
import {
  AvsCommitments as StatelessAvsCommitments,
  useChain,
} from '@dao-dao/stateless'
import {
  AvsCommitmentInfo,
  DurationUnits,
  StatefulAvsCommitmentsProps,
} from '@dao-dao/types'

import { useQueryLoadingDataWithError } from '../../hooks'
import { ETH, USDC } from './AvsAssets'
import { OPERATORS } from './AvsOperators'

let id = 0

export const AvsCommitments = (props: StatefulAvsCommitmentsProps) => {
  const { chainId } = useChain()

  const [commitments, setCommitments] = useRecoilState(avsCommitmentsAtom)

  const queryClient = useQueryClient()

  // Initialize commitments.
  const apps = useQueryLoadingDataWithError(
    wasmaticQueries.listApps(queryClient, {
      chainId,
    })
  )
  useEffect(() => {
    if (commitments.length === 0 && !apps.loading && !apps.errored) {
      setCommitments(
        apps.data.flatMap((app): AvsCommitmentInfo[] => [
          {
            id: (++id).toString(),
            app,
            token: ETH,
            operator: OPERATORS[0],
            amount: 1.2 * (1 + (Math.random() - 0.5)),
            value: 3135.27 * (1 + (Math.random() - 0.5)),
            apr: 9.48 * (1 + (Math.random() - 0.5)),
            createdAt: new Date(),
            slashing: 5,
            unbondingPeriod: {
              value: 2,
              units: DurationUnits.Weeks,
            },
          },
          {
            id: (++id).toString(),
            app,
            token: USDC,
            operator: OPERATORS[1],
            amount: 80 * (1 + (Math.random() - 0.5)),
            value: 429.53 * (1 + (Math.random() - 0.5)),
            apr: 12.96 * (1 + (Math.random() - 0.5)),
            createdAt: new Date(),
            slashing: 5,
            unbondingPeriod: {
              value: 2,
              units: DurationUnits.Weeks,
            },
          },
          {
            id: (++id).toString(),
            app,
            token: USDC,
            operator: OPERATORS[2],
            amount: 4500000 * (1 + (Math.random() - 0.5)),
            value: 2489123.9 * (1 + (Math.random() - 0.5)),
            apr: 3.36 * (1 + (Math.random() - 0.5)),
            createdAt: new Date(),
            slashing: 5,
            unbondingPeriod: {
              value: 2,
              units: DurationUnits.Weeks,
            },
          },
        ])
      )
    }
  }, [commitments.length, apps, setCommitments])

  return (
    <StatelessAvsCommitments
      {...props}
      commitments={
        commitments.length
          ? { loading: false, errored: false, data: commitments }
          : apps.loading || apps.errored
          ? apps
          : { loading: true, errored: false }
      }
    />
  )
}
