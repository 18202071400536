import { useQueryClient } from '@tanstack/react-query'
import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useRecoilState } from 'recoil'

import { aiBouncerMessages, aiBouncerSessionId } from '@dao-dao/state'
import {
  aiBouncerCallerQueries,
  lavsTaskQueueQueries,
} from '@dao-dao/state/query'
import {
  AiBouncer as StatelessAiBouncer,
  useDao,
  useSupportedChainContext,
} from '@dao-dao/stateless'
import { AiBouncerForm, StatefulAiBouncerProps } from '@dao-dao/types'
import { ExecuteMsg } from '@dao-dao/types/contracts/AiBouncerCaller'
import {
  executeSmartContract,
  findEventsAttributeValue,
  processError,
  tapFaucetIfNeeded,
} from '@dao-dao/utils'

import { useWallet } from '../../hooks'
import { ConnectWallet } from '../ConnectWallet'

type AiBouncerOutput = {
  success: {
    sessionId: string
    address: string
    messageId: number
    response: string
    decision?: boolean | null
  }
  error: string
}

export const AiBouncer = ({ caller, ...props }: StatefulAiBouncerProps) => {
  const dao = useDao()
  const { chainId } = useSupportedChainContext()
  const { isWalletConnected, address = '', getSigningClient } = useWallet()
  const queryClient = useQueryClient()

  // Create random session ID on mount.
  const [sessionId, setSessionId] = useRecoilState(
    aiBouncerSessionId({ chainId, address, dao: dao.coreAddress })
  )
  const [loading, setLoading] = useState<'sending' | 'thinking'>()
  const [messages, setMessages] = useRecoilState(
    aiBouncerMessages({ chainId, address, dao: dao.coreAddress })
  )

  const formMethods = useForm<AiBouncerForm>({
    defaultValues: {
      message: '',
    },
  })

  // Initialize session ID when its empty (on mount or user change) and a wallet
  // is connected.
  useEffect(() => {
    if (!sessionId && address) {
      setSessionId(nanoid())
    }
  }, [address, sessionId, setSessionId])

  const waitForAiResponse = async (taskId: string) => {
    setLoading('thinking')
    try {
      // Get task queue address from caller.
      const taskQueueAddress = await queryClient.fetchQuery(
        aiBouncerCallerQueries.taskQueue({
          chainId,
          contractAddress: caller,
        })
      )

      // Refresh task queue list.
      await queryClient.refetchQueries({
        queryKey: lavsTaskQueueQueries.list({
          chainId,
          contractAddress: taskQueueAddress,
          args: {},
        }).queryKey,
      })

      // Poll for expiration or completion.
      let task
      while (true) {
        // Invalidate the task query so it refetches immediately.
        await queryClient.invalidateQueries({
          queryKey: lavsTaskQueueQueries.task({
            chainId,
            contractAddress: taskQueueAddress,
            args: {
              id: taskId,
            },
          }).queryKey,
        })

        task = await queryClient.fetchQuery(
          lavsTaskQueueQueries.task({
            chainId,
            contractAddress: taskQueueAddress,
            args: {
              id: taskId,
            },
          })
        )

        if (!('open' in task.status)) {
          break
        }

        await new Promise((resolve) => setTimeout(resolve, 1000))
      }

      // Refresh task queue list.
      await queryClient.refetchQueries({
        queryKey: lavsTaskQueueQueries.list({
          chainId,
          contractAddress: taskQueueAddress,
          args: {},
        }).queryKey,
      })

      if ('completed' in task.status) {
        const output: AiBouncerOutput = task.result

        if (!output) {
          throw new Error('No output found.')
        }

        if (output.error) {
          throw new Error(`AI service error: ${output.error}`)
        }

        // If the user was let into the DAO, refresh voting power queries.
        if (output.success.decision) {
          await Promise.all([
            queryClient.refetchQueries({
              queryKey: dao.getVotingPowerQuery(address).queryKey,
            }),
            queryClient.refetchQueries({
              queryKey: dao.getTotalVotingPowerQuery().queryKey,
            }),
          ])
        }

        // Add AI response message.
        setMessages((curr) => [
          ...curr,
          {
            source: 'ai',
            message:
              output.success.decision === true
                ? 'Welcome to the DAO!'
                : output.success.decision === false
                ? 'Sorry, you are not a good fit for the DAO at this time.'
                : output.success.response,
            status: 'sent',
            taskId,
          },
        ])
      } else {
        // Change message to expired.
        setMessages((curr) =>
          curr.map((m) =>
            m.source === 'user' && m.taskId === taskId
              ? {
                  ...m,
                  status: 'expired',
                }
              : m
          )
        )
        throw new Error('AI timed out. Try again.')
      }
    } catch (err) {
      console.error(err)
      toast.error(
        processError(err, {
          forceCapture: false,
        })
      )
      setLoading(undefined)
    } finally {
      setLoading(undefined)
    }
  }

  // On mount and when session changes, if not in loading state but the most
  // recent message is a user message that was not responded to, check for
  // response.
  useEffect(() => {
    if (
      !loading &&
      messages.length > 0 &&
      messages[messages.length - 1].source === 'user' &&
      messages[messages.length - 1].status === 'sent'
    ) {
      waitForAiResponse(messages[messages.length - 1].taskId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId])

  const onSend = async ({
    message,
    retrying = false,
  }: {
    message: string
    retrying?: boolean
  }) => {
    if (!isWalletConnected || !address) {
      toast.error('Log in to continue.')
      return
    }

    setLoading('sending')

    try {
      // next message ID is the current count of user messages so far
      const messageId = messages.filter((m) => m.source === 'user').length

      // Get task queue address from caller.
      const taskQueueAddress = await queryClient.fetchQuery(
        aiBouncerCallerQueries.taskQueue({
          chainId,
          contractAddress: caller,
        })
      )

      const { requestor } = await queryClient.fetchQuery(
        lavsTaskQueueQueries.config({
          chainId,
          contractAddress: taskQueueAddress,
        })
      )

      const client = await getSigningClient()

      await tapFaucetIfNeeded({
        chainId,
        address,
        client,
      })

      const msg: ExecuteMsg = {
        trigger: {
          message,
          message_id: messageId,
          session_id: sessionId,
        },
      }

      const { events } = await executeSmartContract(
        client,
        address,
        caller,
        msg,
        'open_payment' in requestor ? [requestor.open_payment] : undefined
      )

      const taskId = findEventsAttributeValue(
        events,
        'wasm-task_created_event',
        'task-id'
      )
      if (!taskId) {
        throw new Error('Created task ID not found.')
      }

      if (retrying) {
        // Change message with task ID if retried successfully.
        setMessages((curr) =>
          curr.map((m, index) =>
            m.source === 'user' &&
            m.status === 'expired' &&
            index === curr.length - 1
              ? {
                  ...m,
                  taskId,
                  status: 'sent',
                }
              : m
          )
        )
      } else {
        // Add message if not retrying last message.
        setMessages((curr) => [
          ...curr,
          {
            source: 'user',
            message,
            status: 'sent',
            taskId,
          },
        ])
        formMethods.setValue('message', '')
      }

      await waitForAiResponse(taskId)
    } catch (err) {
      console.error(err)
      toast.error(
        processError(err, {
          forceCapture: false,
        })
      )
      setLoading(undefined)
    } finally {
      setLoading(undefined)
    }
  }

  const onRetry = async () => {
    // Re-send last message.
    const lastMessage = messages[messages.length - 1]
    if (
      !lastMessage ||
      lastMessage.source !== 'user' ||
      lastMessage.status !== 'expired'
    ) {
      return
    }

    await onSend({
      message: lastMessage.message,
      retrying: true,
    })
  }

  return (
    <FormProvider {...formMethods}>
      <StatelessAiBouncer
        {...props}
        ConnectWallet={ConnectWallet}
        isWalletConnected={isWalletConnected}
        loading={loading}
        messages={messages}
        onRetry={onRetry}
        onSend={onSend}
      />
    </FormProvider>
  )
}
