import { useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { useRecoilState } from 'recoil'

import { HugeDecimal } from '@dao-dao/math'
import { wasmaticQueries } from '@dao-dao/state/query'
import { avsCommitmentsAtom } from '@dao-dao/state/recoil'
import {
  NewCommitmentCard as StatelessNewCommitmentCard,
  useChain,
} from '@dao-dao/stateless'
import {
  AvsCommitmentInfo,
  AvsOperatorInfo,
  DurationUnits,
  GenericTokenBalance,
  LoadingDataWithError,
  NewCommitmentForm,
  StatefulNewCommitmentCardProps,
} from '@dao-dao/types'

import { useQueryLoadingDataWithError } from '../../hooks'
import { DAI, ETH, USDC } from './AvsAssets'
import { OPERATORS } from './AvsOperators'

export const NewCommitmentCard = (props: StatefulNewCommitmentCardProps) => {
  const { chainId } = useChain()

  const [commitments, setCommitments] = useRecoilState(avsCommitmentsAtom)

  const queryClient = useQueryClient()
  const apps = useQueryLoadingDataWithError(
    wasmaticQueries.listApps(queryClient, {
      chainId,
    })
  )

  const operators: LoadingDataWithError<AvsOperatorInfo[]> = {
    loading: false,
    errored: false,
    data: OPERATORS,
  }

  const assets: LoadingDataWithError<GenericTokenBalance[]> = {
    loading: false,
    errored: false,
    data: [
      {
        token: ETH,
        balance: HugeDecimal.fromHumanReadable(1.0942, ETH.decimals).toString(),
      },
      {
        token: DAI,
        balance: HugeDecimal.fromHumanReadable(
          3482139.4,
          DAI.decimals
        ).toString(),
      },
      {
        token: USDC,
        balance: HugeDecimal.fromHumanReadable(
          502.901831,
          USDC.decimals
        ).toString(),
      },
    ],
  }

  const onSubmit = (data: NewCommitmentForm) => {
    if (
      apps.loading ||
      apps.errored ||
      operators.loading ||
      operators.errored
    ) {
      return
    }

    const app = apps.data.find((app) => app.name === data.app)
    if (!app) {
      toast.error('App not found')
      return
    }

    const operator = operators.data.find((o) => o.name === data.operator)
    if (!operator) {
      toast.error('Operator not found')
      return
    }

    const price = Math.random() * 10
    const amount = HugeDecimal.fromHumanReadable(
      data.amount,
      data.token.decimals
    ).toHumanReadableNumber(data.token.decimals)

    const newCommitment: AvsCommitmentInfo = {
      id: (commitments.length + 1).toString(),
      app,
      token: data.token,
      operator,
      amount,
      value: amount * price,
      apr: Math.random() * 100,
      createdAt: new Date(),
      slashing: 5,
      unbondingPeriod: {
        value: 2,
        units: DurationUnits.Weeks,
      },
    }

    setCommitments((commitments) => [...commitments, newCommitment])
  }

  return (
    <StatelessNewCommitmentCard
      {...props}
      apps={apps}
      assets={assets}
      onSubmit={onSubmit}
      operators={operators}
    />
  )
}
