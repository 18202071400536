import clsx from 'clsx'
import { useForm } from 'react-hook-form'

import { StatelessAvsDemoProps } from '@dao-dao/types'
import { validateJSON, validateRequired } from '@dao-dao/utils'

import { Button } from '../buttons'
import { CosmosMessageDisplay } from '../CosmosMessageDisplay'
import { AbacusEmoji, JoystickEmoji, RobotEmoji } from '../emoji'
import {
  CodeMirrorInput,
  FormSwitchCard,
  InputErrorMessage,
  InputLabel,
  NumericInput,
  TextAreaInput,
} from '../inputs'
import { LayerCard } from '../layer'
import { SteppedWalkthrough } from '../SteppedWalkthrough'

export const AvsDemo = ({
  app,
  onSubmit,
  step,
  loading,
  errored,
  taskCreationFee,
  payload,
  result,
  isWalletConnected,
  ConnectWallet,
  TokenAmountDisplay,
  className,
}: StatelessAvsDemoProps) => {
  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: 0,
      prompt: '',
      raw: JSON.stringify({ input: '' }, null, 2),
      test: false,
    },
  })

  return (
    <div className={clsx('flex flex-col gap-4 items-start', className)}>
      <p className="header-text">Demo</p>

      <LayerCard
        Icon={
          app.name === 'square'
            ? AbacusEmoji
            : app.name === 'ollama'
            ? RobotEmoji
            : JoystickEmoji
        }
        className="md:min-w-[24rem]"
        subtitle={
          app.name === 'square'
            ? 'Math Function'
            : app.name === 'ollama'
            ? 'llama3.2:1b'
            : undefined
        }
        subtitleStyle="brand"
        title={
          app.name === 'square'
            ? 'Compute Squares'
            : app.name === 'ollama'
            ? 'AI Chat'
            : 'New Task'
        }
      >
        <form
          className="bg-background-tertiary border-border-secondary p-2.5 rounded-md border overflow-hidden flex flex-col gap-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          {app.testable && (
            <FormSwitchCard
              containerClassName="self-start mb-4"
              fieldName="test"
              label="Test"
              setValue={setValue}
              watch={watch}
            />
          )}

          {app.name === 'square' ? (
            <>
              <InputLabel name="Amount" />
              <div className="flex flex-row gap-2 items-stretch">
                <NumericInput
                  containerClassName="grow"
                  fieldName="amount"
                  min={0}
                  numericValue
                  register={register}
                  step={1}
                  validation={[validateRequired]}
                />

                {!isWalletConnected ? (
                  <ConnectWallet size="md" />
                ) : (
                  <Button
                    type="submit"
                    variant={loading ? 'secondary' : 'brand'}
                  >
                    {loading ? 'Cancel' : 'Submit'}
                  </Button>
                )}
              </div>
              <InputErrorMessage error={errors?.amount} />
            </>
          ) : app.name === 'ollama' ? (
            <>
              <InputLabel name="Prompt" />
              <TextAreaInput
                fieldName="prompt"
                register={register}
                validation={[validateRequired]}
              />
              <InputErrorMessage error={errors?.amount} />

              {!isWalletConnected ? (
                <ConnectWallet center size="md" />
              ) : (
                <Button
                  center
                  type="submit"
                  variant={loading ? 'secondary' : 'brand'}
                >
                  {loading ? 'Cancel' : 'Submit'}
                </Button>
              )}
            </>
          ) : (
            <>
              <InputLabel name="Input (JSON)" />
              <CodeMirrorInput
                control={control}
                fieldName="raw"
                validation={[validateRequired, validateJSON]}
              />
              <InputErrorMessage error={errors?.raw} />

              {!isWalletConnected ? (
                <ConnectWallet center size="md" />
              ) : (
                <Button
                  center
                  type="submit"
                  variant={loading ? 'secondary' : 'brand'}
                >
                  {loading ? 'Cancel' : 'Submit'}
                </Button>
              )}
            </>
          )}
        </form>

        {step >= 0 && (
          <SteppedWalkthrough
            className="pr-8"
            showStepContentStatuses={['past', 'current']}
            stepIndex={step}
            steps={[
              {
                label: 'Validating input...',
                errored: errored && step === 0,
              },
              {
                label: 'Retrieving task submission policy...',
                errored: errored && step === 1,
                overrideShowStepContentStatuses: ['past'],
                content: () => (
                  <div className="space-y-1">
                    <p className="caption-text">Task Creation Fee</p>
                    {taskCreationFee ? (
                      <TokenAmountDisplay coin={taskCreationFee} />
                    ) : (
                      <p className="body-text">None</p>
                    )}
                  </div>
                ),
              },
              {
                label: 'Queueing...',
                errored: errored && step === 2,
                content: payload
                  ? () => (
                      <CosmosMessageDisplay
                        value={JSON.stringify(payload, null, 2)}
                      />
                    )
                  : undefined,
              },
              {
                label: 'Waiting for operator...',
                errored: errored && step === 3,
              },
              {
                label: 'Executed.',
                errored: errored && step === 4,
                content: result
                  ? () => (
                      <CosmosMessageDisplay
                        value={JSON.stringify(result, null, 2)}
                      />
                    )
                  : undefined,
              },
            ]}
          />
        )}
      </LayerCard>
    </div>
  )
}
