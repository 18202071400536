/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { UseQueryOptions } from '@tanstack/react-query'

import {
  AllVotersResponse,
  TotalPowerResponse,
  VotingPowerResponse,
} from '@dao-dao/types/contracts/LavsMockOperators'
import { getCosmWasmClientForChainId } from '@dao-dao/utils'

import { LavsMockOperatorsQueryClient } from '../../../contracts/LavsMockOperators'

export const lavsMockOperatorsQueryKeys = {
  contract: [
    {
      contract: 'lavsMockOperators',
    },
  ] as const,
  address: (chainId: string, contractAddress: string) =>
    [
      {
        ...lavsMockOperatorsQueryKeys.contract[0],
        chainId,
        address: contractAddress,
      },
    ] as const,
  votingPowerAtHeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...lavsMockOperatorsQueryKeys.address(chainId, contractAddress)[0],
        method: 'voting_power_at_height',
        args,
      },
    ] as const,
  totalPowerAtHeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...lavsMockOperatorsQueryKeys.address(chainId, contractAddress)[0],
        method: 'total_power_at_height',
        args,
      },
    ] as const,
  allVoters: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...lavsMockOperatorsQueryKeys.address(chainId, contractAddress)[0],
        method: 'all_voters',
        args,
      },
    ] as const,
}
export const lavsMockOperatorsQueries = {
  votingPowerAtHeight: <TData = VotingPowerResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: LavsMockOperatorsVotingPowerAtHeightQuery<TData>): UseQueryOptions<
    VotingPowerResponse,
    Error,
    TData
  > => ({
    queryKey: lavsMockOperatorsQueryKeys.votingPowerAtHeight(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      return new LavsMockOperatorsQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).votingPowerAtHeight({
        address: args.address,
        height: args.height,
      })
    },
    ...options,
  }),
  totalPowerAtHeight: <TData = TotalPowerResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: LavsMockOperatorsTotalPowerAtHeightQuery<TData>): UseQueryOptions<
    TotalPowerResponse,
    Error,
    TData
  > => ({
    queryKey: lavsMockOperatorsQueryKeys.totalPowerAtHeight(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () => {
      return new LavsMockOperatorsQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).totalPowerAtHeight({
        height: args.height,
      })
    },
    ...options,
  }),
  allVoters: <TData = AllVotersResponse>({
    chainId,
    contractAddress,
    options,
  }: LavsMockOperatorsAllVotersQuery<TData>): UseQueryOptions<
    AllVotersResponse,
    Error,
    TData
  > => ({
    queryKey: lavsMockOperatorsQueryKeys.allVoters(chainId, contractAddress),
    queryFn: async () => {
      return new LavsMockOperatorsQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).allVoters()
    },
    ...options,
  }),
}
export interface LavsMockOperatorsReactQuery<TResponse, TData = TResponse> {
  chainId: string
  contractAddress: string
  options?: Omit<
    UseQueryOptions<TResponse, Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: undefined
  }
}
export interface LavsMockOperatorsAllVotersQuery<TData>
  extends LavsMockOperatorsReactQuery<AllVotersResponse, TData> {}
export interface LavsMockOperatorsTotalPowerAtHeightQuery<TData>
  extends LavsMockOperatorsReactQuery<TotalPowerResponse, TData> {
  args: {
    height?: number
  }
}
export interface LavsMockOperatorsVotingPowerAtHeightQuery<TData>
  extends LavsMockOperatorsReactQuery<VotingPowerResponse, TData> {
  args: {
    address: string
    height?: number
  }
}
