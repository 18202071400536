import { lavsTaskQueueQueries } from '@dao-dao/state/query'
import {
  AvsTaskQueue as StatelessAvsTaskQueue,
  useChain,
} from '@dao-dao/stateless'
import { StatefulAvsTaskQueueProps } from '@dao-dao/types'
import { getAvsTaskQueueAddress } from '@dao-dao/utils'

import { useQueryLoadingDataWithError } from '../../hooks'

export const AvsTaskQueue = (props: StatefulAvsTaskQueueProps) => {
  const { chainId } = useChain()

  const taskQueueAddress = getAvsTaskQueueAddress(props.app)

  const tasks = useQueryLoadingDataWithError(
    lavsTaskQueueQueries.list({
      chainId,
      contractAddress: taskQueueAddress,
      args: {},
      options: {
        // Refresh every 5 seconds.
        refetchInterval: 5 * 1000,
      },
    }),
    (data) => data.tasks
  )

  return <StatelessAvsTaskQueue {...props} tasks={tasks} />
}
