import { NextSeo } from 'next-seo'
import { useRecoilValue } from 'recoil'

import { HugeDecimal } from '@dao-dao/math'
import { avsCommitmentsAtom } from '@dao-dao/state/recoil'
import { VaultPage as StatelessVaultPage } from '@dao-dao/stateless'

import { AvsCommitments, NewCommitmentCard } from '../avs'
import { PageHeaderContent } from '../PageHeaderContent'

export const VaultPage = () => {
  const commitments = useRecoilValue(avsCommitmentsAtom)
  const vaultValue = HugeDecimal.fromHumanReadable(
    commitments.reduce((acc, commitment) => acc + commitment.value, 0),
    2
  ).toInternationalizedHumanReadableString({
    decimals: 2,
    showFullAmount: true,
    minDecimals: 2,
  })

  return (
    <>
      <NextSeo
        description="View your commitments."
        openGraph={{
          title: 'Your Vault',
          description: 'View your commitments.',
        }}
        title="Your Vault"
      />

      <PageHeaderContent title="Your Vault" />

      <StatelessVaultPage
        AvsCommitments={AvsCommitments}
        NewCommitmentCard={NewCommitmentCard}
        vaultValue={vaultValue}
      />
    </>
  )
}
