import { DehydratedState } from '@tanstack/react-query'
import clsx from 'clsx'
import { NextPage } from 'next'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'

import { Home as StatelessHome } from '@dao-dao/stateless'
import { HomePageStats } from '@dao-dao/types'
import { SITE_URL, UNDO_PAGE_PADDING_TOP_CLASSES } from '@dao-dao/utils'

import { WalletActionsProvider } from '../../actions/providers/wallet'
import { useWallet } from '../../hooks'
import { PageHeaderContent } from '../PageHeaderContent'
import { ProfileActions } from '../profile'

export type StatefulHomeProps = {
  /**
   * Stats for all chains or an individual chain if on a chain-specific home.
   */
  stats: HomePageStats
  /**
   * Dehydrated react query state used by the server to preload data. This is
   * accessed in the _app.tsx file.
   */
  reactQueryDehydratedState?: DehydratedState
}

export const Home: NextPage<StatefulHomeProps> = ({ stats }) => {
  const router = useRouter()
  const { address } = useWallet()

  return (
    <>
      <NextSeo
        openGraph={{
          url: SITE_URL + router.asPath,
        }}
      />

      <PageHeaderContent title="Home" />

      <div className={clsx('pt-6', UNDO_PAGE_PADDING_TOP_CLASSES)}>
        <StatelessHome
          ProfileActions={ProfileActions}
          WalletActionsProvider={WalletActionsProvider}
          stats={stats}
          walletAddress={address}
        />
      </div>
    </>
  )
}
