import { useTranslation } from 'react-i18next'

import { AvsCommitmentInfo } from '@dao-dao/types'
import {
  convertDurationWithUnitsToHumanReadableString,
  formatDateTime,
  formatPercentOf100,
} from '@dao-dao/utils'

import { OperatorDisplay } from '../ImageTextDisplay'
import { LayerCard, LayerInfoList } from '../layer'
import { Sankey, SankeyData } from '../Sankey'
import { TokenAmountDisplay } from '../token'

export type CommitmentInfoCardProps = {
  /**
   * The commitment to display information for.
   */
  commitment: AvsCommitmentInfo
  /**
   * All commitments Sankey data.
   */
  sankeyData: SankeyData
  /**
   * Which index link in the Sankey data corresponds with this commitment.
   */
  linkIndex: number
  /**
   * Optional class name.
   */
  className?: string
}

export const CommitmentInfoCard = ({
  commitment,
  sankeyData,
  linkIndex,
  className,
}: CommitmentInfoCardProps) => {
  const { t } = useTranslation()
  const sankeyDataWithOnlyThisLink: SankeyData = {
    nodes: sankeyData.nodes,
    links: sankeyData.links.map((l, index) => ({
      ...l,
      style: index === linkIndex ? 'bright' : 'dim',
    })),
  }

  return (
    <LayerCard className={className} title="Commitment">
      <div className="flex flex-col gap-4">
        <Sankey
          className="p-4 bg-background-secondary rounded-md"
          data={sankeyDataWithOnlyThisLink}
          disableHover
          maxHeight={200}
          minHeight={100}
        />

        <div className="grid grid-cols-2 gap-8 px-4">
          <div className="flex flex-col gap-1">
            <p className="caption-text text-text-secondary">Committed</p>
            <TokenAmountDisplay
              amount={commitment.value}
              className="primary-text font-mono text-lg"
              estimatedUsdValue
              hideSymbol
              showFullAmount
            />
          </div>

          <div className="flex flex-col gap-1">
            <p className="caption-text text-text-secondary">APR</p>
            <p className="primary-text text-text-interactive-valid font-mono text-lg">
              {formatPercentOf100(commitment.apr)}
            </p>
          </div>
        </div>

        <LayerInfoList
          className="px-4 pb-4"
          items={[
            {
              label: 'Operator',
              value: <OperatorDisplay operator={commitment.operator} />,
            },
            {
              label: 'Start date',
              value: formatDateTime(commitment.createdAt),
              valueClassName: 'text-text-secondary',
            },
            {
              label: 'Slashing',
              value: formatPercentOf100(commitment.slashing),
              valueClassName: 'text-text-interactive-error',
            },
            {
              label: 'Unbonding period',
              value: convertDurationWithUnitsToHumanReadableString(
                t,
                commitment.unbondingPeriod
              ),
            },
          ]}
        />
      </div>
    </LayerCard>
  )
}
