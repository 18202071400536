import { StatelessAvsTaskQueueProps } from '@dao-dao/types'
import { InfoStatus } from '@dao-dao/types/contracts/LavsTaskQueue'
import { convertTimestampToDate, formatDateTime } from '@dao-dao/utils'

import { CosmosMessageDisplay } from '../CosmosMessageDisplay'
import { ErrorPage } from '../error'
import { LayerBadge, LayerCard, LayerTable } from '../layer'

export const AvsTaskQueue = ({
  tasks,
  className,
}: StatelessAvsTaskQueueProps) => {
  return (
    <LayerCard className={className} title="Task Queue">
      {tasks.errored ? (
        <ErrorPage error={tasks.error} />
      ) : (
        <LayerTable
          columns={[
            {
              label: 'Task ID',
            },
            {
              label: 'Added on',
              className: 'text-text-tertiary',
            },
            {
              label: 'Finished on',
              className: 'text-text-tertiary',
            },
            {
              label: 'Status',
            },
          ]}
          rows={
            tasks.loading
              ? [...Array(5)].map((_, index) => ({
                  id: index.toString(),
                  loading: true,
                  values: [],
                }))
              : tasks.data.map((task) => ({
                  id: task.id,
                  values: [
                    task.id,
                    formatDateTime(convertTimestampToDate(task.created_at)),
                    'completed' in task.status
                      ? formatDateTime(
                          convertTimestampToDate(
                            task.status.completed.completed
                          )
                        )
                      : '-',
                    <AvsTaskQueueStatus key="status" status={task.status} />,
                  ],
                  details: (
                    <>
                      <div className="flex flex-col gap-1">
                        <p className="caption-text">Description</p>
                        <p className="body-text">{task.description}</p>
                      </div>

                      <div className="flex flex-col sm:flex-row gap-4 items-stretch">
                        <div className="flex flex-col gap-1 grow min-w-[30%]">
                          <p className="caption-text">Input</p>
                          <CosmosMessageDisplay
                            className="grow"
                            value={JSON.stringify(task.payload, null, 2)}
                          />
                        </div>

                        {!!task.result && (
                          <div className="flex flex-col gap-1 grow min-w-[30%]">
                            <p className="caption-text">Output</p>
                            <CosmosMessageDisplay
                              className="grow"
                              value={JSON.stringify(task.result, null, 2)}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  ),
                }))
          }
        />
      )}
    </LayerCard>
  )
}

type AvsTaskQueueStatusProps = {
  status: InfoStatus
}

const AvsTaskQueueStatus = ({ status }: AvsTaskQueueStatusProps) => (
  <LayerBadge
    label={
      'open' in status ? 'QUEUED' : 'expired' in status ? 'EXPIRED' : 'COMPLETE'
    }
    style={
      'open' in status ? 'warning' : 'expired' in status ? 'error' : 'valid'
    }
  />
)
