import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'

import {
  PageLoader,
  AvsDemoPage as StatelessAvsDemoPage,
} from '@dao-dao/stateless'
import { WasmaticApp } from '@dao-dao/types'
import { getAvsPath } from '@dao-dao/utils'

import { AvsDemo } from '../avs'
import { ButtonLink } from '../ButtonLink'
import { PageHeaderContent } from '../PageHeaderContent'

export type AvsDemoPageProps = {
  /**
   * The AVS. If undefined, this is a fallback page loading the AVS.
   */
  app?: WasmaticApp
}

export const AvsDemoPage = ({ app }: AvsDemoPageProps) => {
  const router = useRouter()

  if (router.isFallback || !app) {
    return <PageLoader />
  }

  return (
    <>
      <NextSeo
        description="A demo of an actively validated service."
        openGraph={{
          title: `${app.name} demo`,
          description: 'A demo of an actively validated service.',
        }}
        title={`${app.name} demo`}
      />

      <PageHeaderContent
        breadcrumbs={{
          crumbs: [
            {
              href: getAvsPath(),
              label: 'Services',
            },
            {
              href: getAvsPath(app.name),
              label: app.name,
            },
          ],
          current: 'Demo',
        }}
      />

      <StatelessAvsDemoPage
        AvsDemo={AvsDemo}
        ButtonLink={ButtonLink}
        app={app}
      />
    </>
  )
}
