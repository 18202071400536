import { toUtf8 } from '@cosmjs/encoding'
import { SigningStargateClient, isDeliverTxFailure } from '@cosmjs/stargate'
import { useQueryClient } from '@tanstack/react-query'
import JSON5 from 'json5'
import { nanoid } from 'nanoid'
import { useRouter } from 'next/router'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { fromBase64 } from 'secretjs'

import { HugeDecimal } from '@dao-dao/math'
import { makeGetSignerOptions } from '@dao-dao/state'
import { contractQueries, wasmaticQueries } from '@dao-dao/state/query'
import {
  NewAvsCard as StatelessNewAvsCard,
  useSupportedChainContext,
} from '@dao-dao/stateless'
import { NewAvsForm, StatefulNewAvsCardProps } from '@dao-dao/types'
import { InstantiateMsg } from '@dao-dao/types/contracts/LavsTaskQueue'
import { MsgInstantiateContract2 } from '@dao-dao/types/protobuf/codegen/cosmwasm/wasm/v1/tx'
import {
  CHAIN_GAS_MULTIPLIER,
  createDeliverTxResponseErrorMessage,
  getAvsPath,
  getRpcForChainId,
  processError,
  tapFaucetIfNeeded,
} from '@dao-dao/utils'

import { useQueryLoadingDataWithError, useWallet } from '../../hooks'
import { ConnectWallet } from '../ConnectWallet'
import { Trans } from '../Trans'

export const NewAvsCard = (props: StatefulNewAvsCardProps) => {
  const {
    chainId,
    chain: { chainName },
    config: { codeIds, wasmaticUrl },
  } = useSupportedChainContext()
  const { isWalletConnected, address, getOfflineSignerDirect } = useWallet()
  const queryClient = useQueryClient()
  const router = useRouter()

  const apps = useQueryLoadingDataWithError(
    wasmaticQueries.listApps(queryClient, {
      chainId,
    })
  )

  const [step, setStep] = useState(-1)
  const [errored, setErrored] = useState(false)

  const onSubmit = async (data: NewAvsForm) => {
    if (!isWalletConnected || !address) {
      toast.error('Log in to continue.')
      return
    }

    setStep(0)
    setErrored(false)

    try {
      if (!data.file.data || !data.file.sha256sum) {
        throw new Error('File data or sha256sum not found.')
      }

      const [operators, { apps, digests }] = await Promise.all([
        queryClient.fetchQuery(
          wasmaticQueries.listOperators({
            chainId,
          })
        ),
        queryClient
          .fetchQuery(
            wasmaticQueries.listApps(queryClient, {
              chainId,
            })
          )
          .then((apps) =>
            queryClient
              .fetchQuery(
                wasmaticQueries.listDigests(queryClient, {
                  chainId,
                })
              )
              .then((digests) => ({ apps, digests }))
          ),
      ])

      if (apps.some((a) => a.name === data.name)) {
        throw new Error('AVS with this name already exists.')
      }

      setStep(1)

      // const client = await getSigningClient()
      const client = await SigningStargateClient.connectWithSigner(
        getRpcForChainId(chainId),
        getOfflineSignerDirect(),
        makeGetSignerOptions(queryClient)(chainName)
      )

      await tapFaucetIfNeeded({
        chainId,
        address,
        client,
      })

      const salt = nanoid()
      const [mockOperators, verifierSimple, taskQueue] = await Promise.all([
        queryClient.fetchQuery(
          contractQueries.instantiate2Address(queryClient, {
            chainId,
            creator: address,
            codeId: codeIds.LavsMockOperators,
            salt,
          })
        ),
        queryClient.fetchQuery(
          contractQueries.instantiate2Address(queryClient, {
            chainId,
            creator: address,
            codeId: codeIds.LavsVerifierSimple,
            salt,
          })
        ),
        queryClient.fetchQuery(
          contractQueries.instantiate2Address(queryClient, {
            chainId,
            creator: address,
            codeId: codeIds.LavsTaskQueue,
            salt,
          })
        ),
      ])

      const taskQueueInitMsg: InstantiateMsg = {
        requestor: {
          open_payment: HugeDecimal.fromHumanReadable(
            data.taskCreationFee.amount,
            data.taskCreationFee.token.decimals
          ).toCoin(data.taskCreationFee.token.denomOrAddress),
        },
        timeout: {
          // 5 minutes in nanoseconds
          default: BigInt(5 * 60 * 1e9).toString(),
        },
        verifier: verifierSimple,
      }

      // Instantiate all contracts.
      const result = await client.signAndBroadcast(
        address,
        [
          {
            typeUrl: MsgInstantiateContract2.typeUrl,
            value: MsgInstantiateContract2.fromPartial({
              sender: address,
              admin: address,
              codeId: BigInt(codeIds.LavsMockOperators),
              label: `Mock Operators: ${data.name}`,
              msg: toUtf8(
                JSON.stringify({
                  operators: operators.slice(0, 1).map((addr) => ({
                    addr,
                    voting_power: 1,
                  })),
                })
              ),
              salt: toUtf8(salt),
              fixMsg: false,
            }),
          },
          {
            typeUrl: MsgInstantiateContract2.typeUrl,
            value: MsgInstantiateContract2.fromPartial({
              sender: address,
              admin: address,
              codeId: BigInt(codeIds.LavsVerifierSimple),
              label: `Verifier Simple: ${data.name}`,
              msg: toUtf8(
                JSON.stringify({
                  operator_contract: mockOperators,
                  required_percentage: 70,
                })
              ),
              salt: toUtf8(salt),
              fixMsg: false,
            }),
          },
          {
            typeUrl: MsgInstantiateContract2.typeUrl,
            value: MsgInstantiateContract2.fromPartial({
              sender: address,
              admin: address,
              codeId: BigInt(codeIds.LavsTaskQueue),
              label: `Task Queue: ${data.name}`,
              msg: toUtf8(JSON.stringify(taskQueueInitMsg)),
              salt: toUtf8(salt),
              fixMsg: false,
            }),
          },
        ],
        CHAIN_GAS_MULTIPLIER
      )

      if (isDeliverTxFailure(result)) {
        throw new Error(createDeliverTxResponseErrorMessage(result))
      }

      setStep(2)

      const digest = `sha256:${data.file.sha256sum}`

      // Only upload digest if not already uploaded.
      if (!digests.includes(digest)) {
        const uploadResponse = await fetch(wasmaticUrl + '/upload', {
          method: 'POST',
          body: fromBase64(data.file.data),
        })
        if (!uploadResponse.ok) {
          throw new Error(
            `Failed to upload file to wasmatic: [${uploadResponse.status} ${
              uploadResponse.statusText
            }] ${await uploadResponse.text().catch(() => '')}`.trim()
          )
        }
      }

      setStep(3)

      const deployResponse = await fetch(wasmaticUrl + '/app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: data.name,
          trigger: {
            queue: {
              taskQueueAddr: taskQueue,
              hdIndex: 0,
              pollInterval: 3,
            },
          },
          permissions: {},
          envs: Object.entries(JSON5.parse(data.envs)),
          testable: true,
          digest,
        }),
      })

      if (!deployResponse.ok) {
        throw new Error(
          `Failed to deploy app to wasmatic: [${deployResponse.status} ${
            deployResponse.statusText
          }] ${await deployResponse.text().catch(() => '')}`.trim()
        )
      }

      await Promise.all([
        queryClient.refetchQueries({
          queryKey: wasmaticQueries.listApps(queryClient, {
            chainId,
          }).queryKey,
        }),
        queryClient.refetchQueries({
          queryKey: wasmaticQueries.listDigests(queryClient, {
            chainId,
          }).queryKey,
        }),
      ])

      setStep(5)

      toast.success('AVS deployed successfully. Redirecting...')
      router.push(getAvsPath(data.name))
    } catch (err) {
      console.error(err)
      toast.error(
        processError(err, {
          forceCapture: false,
        })
      )
      setErrored(true)
    }
  }

  return (
    <StatelessNewAvsCard
      {...props}
      ConnectWallet={ConnectWallet}
      Trans={Trans}
      apps={apps}
      errored={errored}
      isWalletConnected={isWalletConnected}
      onSubmit={onSubmit}
      step={step}
    />
  )
}
