import clsx from 'clsx'
import { ComponentType } from 'react'

export type LayerCardIconProps = {
  /**
   * Icon component.
   */
  Icon: ComponentType<{ className: string }>
  /**
   * Style of the icon.
   */
  style: 'card' | 'card-sm' | 'ghost' | 'header'
}

export const LayerCardIcon = ({ Icon, style }: LayerCardIconProps) => {
  const containerClassName = clsx('flex flex-col items-center justify-center', {
    'pl-1': style === 'ghost',
    'w-9 h-9': style === 'card-sm',
    'w-10 h-10': style === 'card',
    'w-14 h-14': style === 'header',

    'bg-background-primary rounded-md border border-border-primary':
      style !== 'ghost',
  })

  const iconClassName = clsx({
    'text-[1.5rem] !h-6 !w-6 !leading-6': style === 'ghost',
    'text-[1.75rem] !h-7 !w-7 !leading-7':
      style === 'card' || style === 'card-sm',
    'text-[2.25rem] !h-10 !w-10 !leading-10': style === 'header',
  })

  return (
    <div className={containerClassName}>
      <Icon className={iconClassName} />
    </div>
  )
}
