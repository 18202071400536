import { AvsOperators as StatelessAvsOperators } from '@dao-dao/stateless'
import {
  AvsOperatorInfo,
  LoadingDataWithError,
  StatefulAvsOperatorsProps,
} from '@dao-dao/types'

export const OPERATORS: AvsOperatorInfo[] = [
  {
    address: 'InfStones',
    name: 'InfStones',
    imageUrl: '/placeholders/1.svg',
  },
  {
    address: 'EigenYields',
    name: 'EigenYields',
    imageUrl: '/placeholders/2.svg',
  },
  {
    address: 'Puffer',
    name: 'Puffer',
    imageUrl: '/placeholders/3.svg',
  },
]

export const AvsOperators = (props: StatefulAvsOperatorsProps) => {
  const operators: LoadingDataWithError<AvsOperatorInfo[]> = {
    loading: false,
    errored: false,
    data: OPERATORS,
  }

  return <StatelessAvsOperators {...props} operators={operators} />
}
