import { WasmaticApp } from '@dao-dao/types'

/**
 * Get the task queue contract address for a given AVS, or error if it doesn't
 * use a task queue.
 */
export const getAvsTaskQueueAddress = (app: WasmaticApp) => {
  if (!('queue' in app.trigger)) {
    throw new Error(`AVS ${app.name} does not use a task queue.`)
  }

  return app.trigger.queue.taskQueueAddr
}
