import { atom, atomFamily } from 'recoil'

import {
  ACTIVE_LAYER_CHAIN_ID,
  SupportedSigningCosmWasmClient,
} from '@dao-dao/utils'

export const signingCosmWasmClientAtom = atomFamily<
  SupportedSigningCosmWasmClient | undefined,
  { chainId: string }
>({
  key: 'signingCosmWasmClient',
  dangerouslyAllowMutability: true,
})

export const walletChainIdAtom = atom<string>({
  key: 'walletChainId',
  default: ACTIVE_LAYER_CHAIN_ID,
  // effects: [
  //   localStorageEffect(JSON.stringify, (jsonValue: string) => {
  //     const value = JSON.parse(jsonValue)
  //     // If no configured chain matches, set to default.
  //     return getConfiguredChains().some(({ chain }) => chain.chainId === value)
  //       ? value
  //       : getSupportedChains()[0].chainId
  //   }),
  // ],
})
