import { atomFamily } from 'recoil'

import { AiBouncerMessage } from '@dao-dao/types'

import { localStorageEffectJSON } from '../effects'

type AiBouncerOptions = { chainId: string; address: string; dao: string }

export const aiBouncerSessionId = atomFamily<string, AiBouncerOptions>({
  key: 'aiBouncerSessionId',
  default: '',
  effects: [localStorageEffectJSON],
})

export const aiBouncerMessages = atomFamily<
  AiBouncerMessage[],
  AiBouncerOptions
>({
  key: 'aiBouncerMessages',
  default: () => [
    {
      source: 'ai',
      message: 'Before I let you in, I need to ask you a few questions.',
      status: 'sent',
      // Empty task ID since this is the first message.
      taskId: '',
    },
  ],
  effects: [localStorageEffectJSON],
})
