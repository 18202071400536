import { Coin, StdFee } from '@cosmjs/amino'
import {
  CosmWasmClient,
  ExecuteResult,
  SigningCosmWasmClient,
} from '@cosmjs/cosmwasm-stargate'

import {
  ConfigResponse,
  Duration,
  HooksResponse,
  ListCompletedResponse,
  ListOpenResponse,
  ListResponse,
  TaskHookType,
  TaskId,
  TaskResponse,
  TaskStatusResponse,
} from '@dao-dao/types/contracts/LavsTaskQueue'
import { CHAIN_GAS_MULTIPLIER } from '@dao-dao/utils'

export interface LavsTaskQueueReadOnlyInterface {
  contractAddress: string
  taskStatus: ({ id }: { id: TaskId }) => Promise<TaskStatusResponse>
  list: ({
    startAfter,
    limit,
  }: {
    startAfter?: TaskId
    limit?: number
  }) => Promise<ListResponse>
  listOpen: ({
    startAfter,
    limit,
  }: {
    startAfter?: TaskId
    limit?: number
  }) => Promise<ListOpenResponse>
  listCompleted: ({
    startAfter,
    limit,
  }: {
    startAfter?: TaskId
    limit?: number
  }) => Promise<ListCompletedResponse>
  task: ({ id }: { id: TaskId }) => Promise<TaskResponse>
  config: () => Promise<ConfigResponse>
  taskHooks: ({ type }: { type: TaskHookType }) => Promise<HooksResponse>
}
export class LavsTaskQueueQueryClient
  implements LavsTaskQueueReadOnlyInterface
{
  client: CosmWasmClient
  contractAddress: string
  constructor(client: CosmWasmClient, contractAddress: string) {
    this.client = client
    this.contractAddress = contractAddress
    this.taskStatus = this.taskStatus.bind(this)
    this.list = this.list.bind(this)
    this.listOpen = this.listOpen.bind(this)
    this.listCompleted = this.listCompleted.bind(this)
    this.task = this.task.bind(this)
    this.config = this.config.bind(this)
    this.taskHooks = this.taskHooks.bind(this)
  }
  taskStatus = async ({ id }: { id: TaskId }): Promise<TaskStatusResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      task_status: {
        id,
      },
    })
  }
  list = async ({
    startAfter,
    limit,
  }: {
    startAfter?: TaskId
    limit?: number
  }): Promise<ListResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      list: {
        start_after: startAfter,
        limit,
      },
    })
  }
  listOpen = async ({
    startAfter,
    limit,
  }: {
    startAfter?: TaskId
    limit?: number
  }): Promise<ListOpenResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      list_open: {
        start_after: startAfter,
        limit,
      },
    })
  }
  listCompleted = async ({
    startAfter,
    limit,
  }: {
    startAfter?: TaskId
    limit?: number
  }): Promise<ListCompletedResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      list_completed: {
        start_after: startAfter,
        limit,
      },
    })
  }
  task = async ({ id }: { id: TaskId }): Promise<TaskResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      task: { id },
    })
  }
  config = async (): Promise<ConfigResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      config: {},
    })
  }
  taskHooks = async ({
    type,
  }: {
    type: TaskHookType
  }): Promise<HooksResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      task_hooks: type,
    })
  }
}
export interface LavsTaskQueueInterface extends LavsTaskQueueReadOnlyInterface {
  contractAddress: string
  sender: string
  complete: (
    {
      taskId,
      response,
    }: {
      taskId: TaskId
      response: any
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  create: (
    {
      description,
      timeout,
      payload,
    }: {
      description: string
      timeout?: Duration
      payload: any
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  timeout: (
    { taskId }: { taskId: TaskId },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  addHook: (
    { type }: { type: TaskHookType },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
}
export class LavsTaskQueueClient
  extends LavsTaskQueueQueryClient
  implements LavsTaskQueueInterface
{
  client: SigningCosmWasmClient
  sender: string
  contractAddress: string
  constructor(
    client: SigningCosmWasmClient,
    sender: string,
    contractAddress: string
  ) {
    super(client, contractAddress)
    this.client = client
    this.sender = sender
    this.contractAddress = contractAddress
    this.complete = this.complete.bind(this)
    this.create = this.create.bind(this)
    this.timeout = this.timeout.bind(this)
    this.addHook = this.addHook.bind(this)
  }
  complete = async (
    {
      taskId,
      response,
    }: {
      taskId: TaskId
      response: any
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        complete: {
          task_id: taskId,
          response,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  create = async (
    {
      description,
      timeout,
      payload,
    }: {
      description: string
      timeout?: Duration
      payload: any
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        create: {
          description,
          timeout,
          payload,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  timeout = async (
    { taskId }: { taskId: TaskId },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        timeout: {
          task_id: taskId,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  addHook = async (
    { type }: { type: TaskHookType },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        add_hook: type,
      },
      fee,
      memo,
      _funds
    )
  }
}
