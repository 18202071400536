import { ArrowForward } from '@mui/icons-material'
import { ComponentType, useState } from 'react'

import {
  ButtonLinkProps,
  StatefulAvsAssetsProps,
  StatefulAvsOperatorsProps,
  StatefulAvsTaskQueueProps,
  WasmaticApp,
} from '@dao-dao/types'
import { getAvsPath } from '@dao-dao/utils'

import { Button } from '../components'

export type AvsViewPageProps = {
  /**
   * Wasmatic app.
   */
  app: WasmaticApp
  /**
   * Delete app callback.
   */
  onDelete: () => void
  /**
   * Whether or not the app is being deleted.
   */
  deleting: boolean
  /**
   * Stateful button link component.
   */
  ButtonLink: ComponentType<ButtonLinkProps>
  /**
   * Stateful assets component.
   */
  AvsAssets: ComponentType<StatefulAvsAssetsProps>
  /**
   * Stateful operators component.
   */
  AvsOperators: ComponentType<StatefulAvsOperatorsProps>
  /**
   * Stateful task queue component.
   */
  AvsTaskQueue: ComponentType<StatefulAvsTaskQueueProps>
}

export const AvsViewPage = ({
  app,
  onDelete,
  deleting,
  ButtonLink,
  AvsAssets,
  AvsOperators,
  AvsTaskQueue,
}: AvsViewPageProps) => {
  const [confirmDelete, setConfirmDelete] = useState(0)

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-center flex-wrap gap-x-8 gap-y-2">
        <p className="header-text">{app.name}</p>

        <div className="flex flex-row gap-2 items-start">
          <Button
            loading={deleting}
            onClick={() => {
              if (confirmDelete >= 2) {
                setConfirmDelete(0)
                onDelete()
              } else {
                setConfirmDelete((c) => c + 1)
              }
            }}
            variant="secondary"
          >
            {confirmDelete === 0
              ? 'Delete'
              : confirmDelete === 1
              ? 'Are you sure?'
              : 'Are you really really really sure?'}
          </Button>

          <ButtonLink href={getAvsPath(app.name, 'demo')} variant="brand">
            Go to Demo
            <ArrowForward className="!w-5 !h-5" />
          </ButtonLink>
        </div>
      </div>

      <p className="body-text mt-3">This is a service, validated actively.</p>

      <div className="h-[1px] w-full bg-border-primary my-12"></div>

      <div className="flex flex-col gap-4 sm:flex-row items-stretch mb-6">
        <AvsAssets app={app} className="grow min-w-[33%]" />

        <AvsOperators app={app} className="grow min-w-[33%]" />
      </div>

      <AvsTaskQueue app={app} />
    </div>
  )
}
