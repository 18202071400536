import { Add, WarningRounded } from '@mui/icons-material'
import { ComponentType, useState } from 'react'

import {
  LoadingDataWithError,
  StatefulAvsCardProps,
  StatefulNewAvsCardProps,
  WasmaticApp,
} from '@dao-dao/types'

import { Button, ErrorPage, GridCardContainer, NoContent } from '../components'

export type AvsListPageProps = {
  /**
   * Wasmatic apps.
   */
  apps: LoadingDataWithError<WasmaticApp[]>
  /**
   * Stateful NewAvsCard component.
   */
  NewAvsCard: ComponentType<StatefulNewAvsCardProps>
  /**
   * Stateful AvsCard component.
   */
  AvsCard: ComponentType<StatefulAvsCardProps>
}

export const AvsListPage = ({
  apps,
  NewAvsCard,
  AvsCard,
}: AvsListPageProps) => {
  const [showingNewAvs, setShowingNewAvs] = useState(false)

  return (
    <div className="flex flex-col">
      <p className="header-text">Actively Validated Services</p>

      <p className="body-text mt-3">
        These are services that are validated, actively.
      </p>

      <div className="flex flex-col items-start mt-6">
        {showingNewAvs ? (
          <NewAvsCard
            className="animate-fade-in"
            onClose={() => setShowingNewAvs(false)}
          />
        ) : (
          <Button
            center
            className="animate-fade-in"
            onClick={() => setShowingNewAvs(true)}
            variant="secondary"
          >
            <Add className="!w-6 !h-6" /> New AVS
          </Button>
        )}
      </div>

      <p className="title-text mt-12">Browse AVSs</p>

      <div className="flex flex-col gap-4 mt-4">
        {apps.errored ? (
          <ErrorPage error={apps.error} />
        ) : apps.loading || apps.data.length > 0 ? (
          <GridCardContainer cardType="tall">
            {apps.loading
              ? [...Array(3)].map((_, index) => (
                  <AvsCard
                    key={index}
                    app={{
                      loading: true,
                      errored: false,
                    }}
                  />
                ))
              : apps.data.map((app, index) => (
                  <AvsCard
                    key={index + app.name}
                    app={{
                      loading: false,
                      errored: false,
                      data: app,
                    }}
                  />
                ))}
          </GridCardContainer>
        ) : (
          <NoContent Icon={WarningRounded} body="No AVSs found." />
        )}
      </div>
    </div>
  )
}
