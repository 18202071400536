import { ArrowDropDown } from '@mui/icons-material'

import { AvsOperatorInfo, LoadingDataWithError } from '@dao-dao/types'
import { getFallbackImage } from '@dao-dao/utils'

import { ImageTextDisplay } from '../ImageTextDisplay'
import { FilterableItemPopup } from '../popup/FilterableItemPopup'

export type AvsOperatorSelectorProps = {
  /**
   * Operators.
   */
  operators: LoadingDataWithError<AvsOperatorInfo[]>
  /**
   * Operator selection callback function.
   */
  onSelect: (app: AvsOperatorInfo) => void
  /**
   * Optionally show the current operator in the trigger button. Otherwise shows
   * a generic selection prompt.
   */
  selectedOperator?: AvsOperatorInfo
}

/**
 * A generic AVS operator selector popup input.
 */
export const AvsOperatorSelector = ({
  operators,
  onSelect,
  selectedOperator,
}: AvsOperatorSelectorProps) => {
  const disabled = operators.errored

  return (
    <FilterableItemPopup
      filterableItemKeys={FILTERABLE_KEYS}
      items={
        operators.loading || operators.errored
          ? []
          : operators.data.map((operator) => ({
              operator,
              key: operator.address,
              label: operator.name,
              iconUrl: operator.imageUrl || getFallbackImage(operator.name),
            }))
      }
      onSelect={({ operator }) => onSelect(operator)}
      searchPlaceholder="Search for an operator..."
      trigger={{
        type: 'button',
        props: {
          contentContainerClassName: 'justify-between',
          loading: operators.loading,
          disabled,
          size: 'lg',
          variant: 'ghost_outline',
          children: (
            <>
              {selectedOperator ? (
                <ImageTextDisplay
                  imageUrl={
                    selectedOperator.imageUrl ||
                    getFallbackImage(selectedOperator.name)
                  }
                  label={selectedOperator.name}
                />
              ) : (
                <p className="text-text-secondary">Select an operator</p>
              )}

              {!disabled && (
                <ArrowDropDown className="!h-6 !w-6 !text-icon-secondary shrink-0" />
              )}
            </>
          ),
        },
      }}
    />
  )
}

const FILTERABLE_KEYS = ['label']
