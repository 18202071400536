import clsx from 'clsx'

export type LayerTextLogoProps = {
  className?: string
}

export const LayerTextLogo = ({ className }: LayerTextLogoProps) => (
  <svg
    className={clsx(
      // fix pixel clipping on edges
      'overflow-visible',
      className
    )}
    fill="none"
    height="20"
    viewBox="0 0 103 20"
    width="103"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M4.55517 0.0758057H0.0166016V19.9264H16.473V15.9559H4.55517V0.0758057ZM27.4874 0.0758057L18.1167 19.9264H22.9489L24.8505 15.7444H35.9432L37.8445 19.9264H42.6768L33.3076 0.0758057H27.4874ZM26.5112 12.0922L30.3967 3.54573L34.2824 12.0922H26.5112ZM48.6438 8.36573L55.2644 0.075885H60.3098L50.9397 11.822V19.9264H46.4011V11.8827L36.977 0.075885H42.0232L48.6438 8.36573ZM66.3663 11.7214H78.6473V8.28085H66.4269L66.3728 3.80756H79.3421V0.075885H62.0884V10.0006V19.9264H79.4761V16.1947H66.3728L66.3663 11.7214ZM102.354 19.9264H97.0372L91.0154 12.3854H86.48V19.9264H81.9374V0.0758057H94.3894C95.8485 0.0758057 97.13 0.336286 98.234 0.857166C99.2823 1.32797 100.18 2.07356 100.833 3.01364C101.456 3.93116 101.768 5.00749 101.768 6.24269C101.767 7.46045 101.456 8.52804 100.833 9.44548C100.184 10.3848 99.2887 11.1308 98.2437 11.6031C97.5666 11.9168 96.8492 12.1348 96.1116 12.2504L102.354 19.9264ZM93.8592 3.62324H86.4752V8.88941H93.8592C94.8736 8.89005 95.679 8.65652 96.2754 8.18869C96.8718 7.72085 97.1703 7.07221 97.1703 6.24269C97.1687 5.41389 96.8701 4.77012 96.2754 4.3114C95.6798 3.8526 94.8744 3.62324 93.8592 3.62324Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
)
