import { AvsAssets as StatelessAvsAssets } from '@dao-dao/stateless'
import {
  AvsAssetInfo,
  GenericToken,
  LoadingDataWithError,
  StatefulAvsAssetsProps,
  TokenType,
} from '@dao-dao/types'

export const ETH: GenericToken = {
  chainId: 'ethereum',
  type: TokenType.Native,
  denomOrAddress: 'eth',
  symbol: 'ETH',
  decimals: 18,
  imageUrl:
    'https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/512/Ethereum-ETH-icon.png',
  source: {
    chainId: 'ethereum',
    type: TokenType.Native,
    denomOrAddress: 'eth',
  },
}

export const USDC: GenericToken = {
  chainId: 'ethereum',
  type: TokenType.Native,
  denomOrAddress: 'usdc',
  symbol: 'USDC',
  decimals: 18,
  imageUrl: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.png',
  source: {
    chainId: 'ethereum',
    type: TokenType.Native,
    denomOrAddress: 'usdc',
  },
}

export const DAI: GenericToken = {
  chainId: 'ethereum',
  type: TokenType.Native,
  denomOrAddress: 'dai',
  symbol: 'DAI',
  decimals: 18,
  imageUrl: 'https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.png',
  source: {
    chainId: 'ethereum',
    type: TokenType.Native,
    denomOrAddress: 'dai',
  },
}

export const AvsAssets = (props: StatefulAvsAssetsProps) => {
  const assets: LoadingDataWithError<AvsAssetInfo[]> = {
    loading: false,
    errored: false,
    data: [
      {
        token: ETH,
        percentOfTotal: 63.8,
      },
      {
        token: USDC,
        percentOfTotal: 14.78,
      },
      {
        token: DAI,
        percentOfTotal: 21.42,
      },
    ],
  }

  return <StatelessAvsAssets {...props} assets={assets} />
}
