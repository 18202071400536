import { DeviceHub } from '@mui/icons-material'

import { StatelessAvsOperatorsProps } from '@dao-dao/types'

import { ErrorPage } from '../error'
import { OperatorDisplay } from '../ImageTextDisplay'
import { LayerBadge, LayerCard, LayerTable } from '../layer'

export const AvsOperators = ({
  operators,
  className,
}: StatelessAvsOperatorsProps) => {
  return (
    <LayerCard
      Icon={DeviceHub}
      className={className}
      subtitle={
        operators.loading
          ? 'Loading...'
          : operators.errored
          ? undefined
          : `${operators.data.length} running this AVS`
      }
      title="Operators"
    >
      {operators.errored ? (
        <ErrorPage error={operators.error} />
      ) : (
        <LayerTable
          columns={[
            {
              label: 'Operator',
            },
            {
              label: 'Dominance',
              className: 'text-text-tertiary',
            },
            {
              label: 'TVL',
              className: 'text-text-tertiary',
            },
            {
              label: 'Stakers',
            },
          ]}
          rows={
            operators.loading
              ? [...Array(3)].map((_, index) => ({
                  id: index.toString(),
                  loading: true,
                  values: [],
                }))
              : operators.data.map((operator) => ({
                  id: operator.address,
                  values: [
                    <OperatorDisplay key="operator" operator={operator} />,
                    '64.3%',
                    '$764.48M',
                    <LayerBadge key="brand" label="4k" style="brand" />,
                  ],
                }))
          }
        />
      )}
    </LayerCard>
  )
}
