import { useQueryClient } from '@tanstack/react-query'
import JSON5 from 'json5'
import { useRef, useState } from 'react'
import toast from 'react-hot-toast'

import { lavsTaskQueueQueries } from '@dao-dao/state/query'
import {
  AvsDemo as StatelessAvsDemo,
  useSupportedChainContext,
} from '@dao-dao/stateless'
import { Coin, StatefulAvsDemoProps } from '@dao-dao/types'
import { ExecuteMsg } from '@dao-dao/types/contracts/LavsTaskQueue'
import {
  executeSmartContract,
  findEventsAttributeValue,
  getAvsTaskQueueAddress,
  processError,
  tapFaucetIfNeeded,
} from '@dao-dao/utils'

import { useWallet } from '../../hooks'
import { ConnectWallet } from '../ConnectWallet'
import { TokenAmountDisplay } from '../TokenAmountDisplay'

export const AvsDemo = (props: StatefulAvsDemoProps) => {
  const {
    chainId,
    config: { wasmaticUrl },
  } = useSupportedChainContext()
  const { isWalletConnected, address, getSigningClient } = useWallet()
  const queryClient = useQueryClient()

  const [step, setStep] = useState(-1)
  const [errored, setErrored] = useState(false)
  const [taskCreationFee, setTaskCreationFee] = useState<Coin>()
  const [payload, setPayload] = useState<any>()
  const [result, setResult] = useState<any>()
  const submissionRef = useRef(0)

  const loading = !errored && step >= 0 && step < 5

  const onSubmit = async (data: any) => {
    // If loading, cancel the current submission.
    if (loading) {
      // Advance submission ref so the previous submission stops progressing.
      submissionRef.current++

      setStep(-1)
      setErrored(false)
      setPayload(undefined)
      setResult(undefined)
      return
    }

    if (!isWalletConnected || !address) {
      toast.error('Log in to continue.')
      return
    }

    const currentSubmission = ++submissionRef.current

    setStep(0)
    setErrored(false)
    setPayload(undefined)
    setResult(undefined)

    try {
      let description: string
      let payload: any

      if (props.app.name === 'square') {
        if (typeof data.amount !== 'number') {
          throw new Error('Amount must be a number.')
        }

        description = `Square ${data.amount}`
        payload = {
          x: data.amount,
        }
        setPayload(payload)
      } else if (props.app.name === 'ollama') {
        if (!data.prompt) {
          throw new Error('Prompt is required.')
        }

        description = 'Prompt'
        payload = {
          prompt: data.prompt,
        }
        setPayload(payload)
      } else {
        description = 'Input'
        payload = JSON5.parse(data.raw)
        setPayload(payload)
      }

      if (props.app.testable && data.test) {
        setStep(3)

        const res = await fetch(wasmaticUrl + '/test', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: props.app.name,
            input: payload,
          }),
        })

        if (currentSubmission !== submissionRef.current) {
          return
        }

        if (!res.ok) {
          throw new Error(
            `Failed to test app: [${res.status} ${res.statusText}] ${await res
              .text()
              .catch(() => '')}`.trim()
          )
        }

        const { output } = await res.json()
        setResult(output)

        setStep(5)
      } else {
        setStep(1)

        const taskQueueContractAddress = getAvsTaskQueueAddress(props.app)

        const {
          requestor,
          timeout: { default: timeout },
        } = await queryClient.fetchQuery(
          lavsTaskQueueQueries.config({
            chainId,
            contractAddress: taskQueueContractAddress,
          })
        )

        setTaskCreationFee(
          'open_payment' in requestor ? requestor.open_payment : undefined
        )

        if ('fixed' in requestor && requestor.fixed !== address) {
          throw new Error(
            `You are not the requestor for this task queue. Only ${requestor.fixed} may submit tasks.`
          )
        }

        if (currentSubmission !== submissionRef.current) {
          return
        }
        setStep(2)

        const client = await getSigningClient()

        await tapFaucetIfNeeded({
          chainId,
          address,
          client,
        })

        if (currentSubmission !== submissionRef.current) {
          return
        }

        const msg: ExecuteMsg = {
          create: {
            description,
            timeout,
            payload,
          },
        }

        const { events } = await executeSmartContract(
          client,
          address,
          taskQueueContractAddress,
          msg,
          'open_payment' in requestor ? [requestor.open_payment] : undefined
        )

        const taskId = findEventsAttributeValue(
          events,
          'wasm-task_created_event',
          'task-id'
        )
        if (!taskId) {
          throw new Error('Created task ID not found.')
        }

        if (currentSubmission !== submissionRef.current) {
          return
        }

        // Refresh task queue list (shown in table).
        await queryClient.refetchQueries({
          queryKey: lavsTaskQueueQueries.list({
            chainId,
            contractAddress: taskQueueContractAddress,
            args: {},
          }).queryKey,
        })

        if (currentSubmission !== submissionRef.current) {
          return
        }
        setStep(3)

        // Poll for expiration or completion.
        let task
        while (true) {
          // Invalidate the task query so it refetches immediately.
          await queryClient.invalidateQueries({
            queryKey: lavsTaskQueueQueries.task({
              chainId,
              contractAddress: taskQueueContractAddress,
              args: {
                id: taskId,
              },
            }).queryKey,
          })

          task = await queryClient.fetchQuery(
            lavsTaskQueueQueries.task({
              chainId,
              contractAddress: taskQueueContractAddress,
              args: {
                id: taskId,
              },
            })
          )

          if (
            !('open' in task.status) ||
            currentSubmission !== submissionRef.current
          ) {
            break
          }

          await new Promise((resolve) => setTimeout(resolve, 1000))
        }

        // Refresh task queue list (shown in table).
        await queryClient.refetchQueries({
          queryKey: lavsTaskQueueQueries.list({
            chainId,
            contractAddress: taskQueueContractAddress,
            args: {},
          }).queryKey,
        })

        if (currentSubmission !== submissionRef.current) {
          return
        }
        setStep(5)

        if ('completed' in task.status) {
          setResult(task.result)
        } else {
          throw new Error('Task expired.')
        }
      }
    } catch (err) {
      if (currentSubmission !== submissionRef.current) {
        return
      }

      console.error(err)
      toast.error(
        processError(err, {
          forceCapture: false,
        })
      )
      setErrored(true)
    }
  }

  return (
    <StatelessAvsDemo
      {...props}
      ConnectWallet={ConnectWallet}
      TokenAmountDisplay={TokenAmountDisplay}
      errored={errored}
      isWalletConnected={isWalletConnected}
      loading={loading}
      onSubmit={onSubmit}
      payload={payload}
      result={result}
      step={step}
      taskCreationFee={taskCreationFee}
    />
  )
}
