import clsx from 'clsx'

import { LinkWrapper } from '../LinkWrapper'
import { LayerTextLogo } from './LayerTextLogo'
import { Logo } from './Logo'

export type LayerFullLogoProps = {
  compact?: boolean
  className?: string
}

export const LayerFullLogo = ({ compact, className }: LayerFullLogoProps) => (
  <LinkWrapper
    className={clsx('flex flex-row items-center gap-2', className)}
    href="/"
  >
    <Logo size={28} />

    {!compact && <LayerTextLogo />}
  </LinkWrapper>
)
