import { WidgetRendererProps } from '@dao-dao/types'

import { AiBouncer } from '../../../components'
import { AiBouncerData } from './types'

export const Renderer = ({
  variables: { caller },
}: WidgetRendererProps<AiBouncerData>) => {
  return <AiBouncer caller={caller} />
}
