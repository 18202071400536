import { toBase64, toHex } from '@cosmjs/encoding'
import { CodeOutlined, DownloadDone } from '@mui/icons-material'
import clsx from 'clsx'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import { HugeDecimal } from '@dao-dao/math'
import { NewAvsForm, StatelessNewAvsCardProps } from '@dao-dao/types'
import {
  getChainAssets,
  getNativeTokenForChainId,
  processError,
  validateJSON,
  validatePositive,
  validateRequired,
} from '@dao-dao/utils'

import { useChain } from '../../contexts'
import { Button } from '../buttons'
import {
  CodeMirrorInput,
  FileDropInput,
  InputErrorMessage,
  InputLabel,
  TextInput,
  TokenInput,
} from '../inputs'
import { LayerCard } from '../layer'
import { SteppedWalkthrough } from '../SteppedWalkthrough'

export const NewAvsCard = ({
  apps,
  onSubmit,
  step,
  errored,
  isWalletConnected,
  ConnectWallet,
  Trans,
  onClose,
  className,
}: StatelessNewAvsCardProps) => {
  const { t } = useTranslation()
  const { chainId } = useChain()

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<NewAvsForm>({
    mode: 'onChange',
    defaultValues: {
      taskCreationFee: {
        amount: '0.01',
        token: getNativeTokenForChainId(chainId),
      },
      envs: '{}',
    },
  })

  const tokens = getChainAssets(chainId)

  const selectedTaskCreationFeeToken = watch('taskCreationFee.token')

  const loading = !errored && step >= 0 && step < 4

  const data = watch('file.data')
  const [fileName, setFileName] = useState<string | undefined>()
  const [processing, setProcessing] = useState(false)
  const onSelect = async (file: File) => {
    if (!file.name.endsWith('.wasm') && !file.name.endsWith('.wasm.gz')) {
      toast.error(t('error.invalidWasmFile'))
      return
    }
    if (!file.size) {
      toast.error(t('error.emptyFile'))
      return
    }

    setProcessing(true)
    try {
      setFileName(file.name)

      const data = await file.arrayBuffer()
      if (!data.byteLength) {
        throw new Error(t('error.emptyFile'))
      }

      const fileData = new Uint8Array(data)
      const sha256Hash = await crypto.subtle.digest('SHA-256', fileData)

      setValue('file.data', toBase64(fileData))
      setValue('file.sha256sum', toHex(new Uint8Array(sha256Hash)))
    } catch (err) {
      console.error(err)
      toast.error(processError(err, { forceCapture: false }))
    } finally {
      setProcessing(false)
    }
  }

  return (
    <LayerCard
      Icon={CodeOutlined}
      className={className}
      onClose={onClose}
      title="New AVS"
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-1">
          <InputLabel name="Name" />
          <TextInput
            error={errors.name}
            fieldName="name"
            register={register}
            validation={[
              validateRequired,
              (name) =>
                !name ||
                apps.loading ||
                apps.errored ||
                !apps.data.some((a) => a.name === name) ||
                'Name already taken.',
            ]}
          />
          <InputErrorMessage error={errors.name} />
        </div>

        <div className="flex flex-col gap-1">
          <InputLabel name="Task Creation Fee" />
          <TokenInput
            amount={{
              fieldName: 'taskCreationFee.amount',
              register,
              watch,
              setValue,
              getValues,
              min: HugeDecimal.one.toHumanReadableNumber(
                selectedTaskCreationFeeToken.decimals
              ),
              step: HugeDecimal.one.toHumanReadableNumber(
                selectedTaskCreationFeeToken.decimals
              ),
              validations: [validateRequired, validatePositive],
            }}
            onSelectToken={(token) => setValue('taskCreationFee.token', token)}
            selectedToken={selectedTaskCreationFeeToken}
            tokens={{ loading: false, data: tokens }}
          />
          <InputErrorMessage error={errors.taskCreationFee?.amount} />
        </div>

        <div className="flex flex-col gap-1">
          <InputLabel name="WASI Component" />
          <FileDropInput
            Icon={data ? DownloadDone : undefined}
            Trans={Trans}
            className={clsx(!!data && 'opacity-60')}
            dragHereOrSelect={fileName}
            loading={processing}
            onSelect={onSelect}
          />
        </div>

        <div className="flex flex-col gap-1">
          <InputLabel name="Environment variables (JSON object)" />
          <CodeMirrorInput
            control={control}
            error={errors?.envs}
            fieldName="envs"
            validation={[validateJSON]}
          />
          <InputErrorMessage error={errors.envs} />
        </div>

        {!isWalletConnected ? (
          <ConnectWallet center className="mt-2" />
        ) : (
          <Button
            center
            className="mt-2"
            loading={loading}
            size="lg"
            type="submit"
            variant="brand"
          >
            Submit
          </Button>
        )}
      </form>

      {step >= 0 && (
        <SteppedWalkthrough
          className="pr-8"
          showStepContentStatuses={['past', 'current']}
          stepIndex={step}
          steps={[
            {
              label: 'Validating input...',
              errored: errored && step === 0,
            },
            {
              label:
                'Instantiating operators, verifier, and task queue contracts...',
              errored: errored && step === 1,
            },
            {
              label: 'Uploading WASM file...',
              errored: errored && step === 2,
            },
            {
              label: 'Deploying AVS...',
              errored: errored && step === 3,
            },
            {
              label: 'Deployed. Redirecting to AVS...',
            },
          ]}
        />
      )}
    </LayerCard>
  )
}
