import { Construction, DeveloperBoard, Handshake } from '@mui/icons-material'
import { ComponentType } from 'react'

import { HomePageStats, WalletActionsProviderProps } from '@dao-dao/types'

import { DaoInfoCards } from '../components'

export type HomeProps = {
  /**
   * The stats.
   */
  stats: HomePageStats
  /**
   * Wallet address if connected.
   */
  walletAddress: string | undefined
  /**
   * Stateful profile actions component.
   */
  ProfileActions: ComponentType
  /**
   * Wallet actions provider component.
   */
  WalletActionsProvider: ComponentType<WalletActionsProviderProps>
}

export const Home = ({
  stats,
  walletAddress,
  ProfileActions,
  WalletActionsProvider,
}: HomeProps) => {
  return (
    <>
      <DaoInfoCards
        cards={[
          {
            Icon: Handshake,
            label: 'Commitments',
            value: stats.commitments.toLocaleString(),
          },
          {
            Icon: DeveloperBoard,
            label: 'AVSs',
            value: stats.avs.toLocaleString(),
          },
          {
            Icon: Construction,
            label: 'Operators',
            value: stats.operators.toLocaleString(),
          },
        ]}
        className="mb-8"
        valueClassName="text-text-interactive-valid font-semibold font-mono"
        wrap
      />

      {!!walletAddress && (
        <div className="flex flex-col gap-4">
          <p className="title-text">Actions</p>

          <WalletActionsProvider address={walletAddress}>
            <ProfileActions />
          </WalletActionsProvider>
        </div>
      )}
    </>
  )
}
