import { useQueryClient } from '@tanstack/react-query'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { useState } from 'react'
import toast from 'react-hot-toast'

import { wasmaticQueries } from '@dao-dao/state/query'
import {
  PageLoader,
  AvsViewPage as StatelessAvsViewPage,
  useSupportedChainContext,
} from '@dao-dao/stateless'
import { WasmaticApp } from '@dao-dao/types'
import { getAvsPath, processError } from '@dao-dao/utils'

import { AvsAssets, AvsOperators, AvsTaskQueue } from '../avs'
import { ButtonLink } from '../ButtonLink'
import { PageHeaderContent } from '../PageHeaderContent'

export type AvsViewPageProps = {
  /**
   * The AVS. If undefined, this is a fallback page loading the AVS.
   */
  app?: WasmaticApp
}

export const AvsViewPage = ({ app }: AvsViewPageProps) => {
  const {
    chainId,
    config: { wasmaticUrl },
  } = useSupportedChainContext()
  const router = useRouter()
  const queryClient = useQueryClient()
  const [deleting, setDeleting] = useState(false)

  if (router.isFallback || !app) {
    return <PageLoader />
  }

  const onDelete = async () => {
    setDeleting(true)
    try {
      const res = await fetch(wasmaticUrl + '/app', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          apps: [app.name],
        }),
      })

      if (!res.ok) {
        throw new Error(
          `Failed to delete app: [${res.status} ${res.statusText}] ${await res
            .text()
            .catch(() => '')}`.trim()
        )
      }

      await queryClient.refetchQueries({
        queryKey: wasmaticQueries.listApps(queryClient, {
          chainId,
        }).queryKey,
      })

      toast.success('App deleted successfully.')
      router.push(getAvsPath())

      // Don't need to hide loader because the page is navigating away. Only set
      // deleting to false on error.
    } catch (err) {
      console.error(err)
      toast.error(
        processError(err, {
          forceCapture: false,
        })
      )
      setDeleting(false)
    }
  }

  return (
    <>
      <NextSeo
        description="An actively validated service."
        openGraph={{
          title: app.name,
          description: 'An active validated service.',
        }}
        title={app.name}
      />

      <PageHeaderContent
        breadcrumbs={{
          crumbs: [
            {
              href: getAvsPath(),
              label: 'Services',
            },
          ],
          current: app.name,
        }}
      />

      <StatelessAvsViewPage
        AvsAssets={AvsAssets}
        AvsOperators={AvsOperators}
        AvsTaskQueue={AvsTaskQueue}
        ButtonLink={ButtonLink}
        app={app}
        deleting={deleting}
        onDelete={onDelete}
      />
    </>
  )
}
