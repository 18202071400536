import clsx from 'clsx'
import { CSSProperties } from 'react'

import { AvsOperatorInfo, GenericToken, WasmaticApp } from '@dao-dao/types'
import { getFallbackImage, toAccessibleImageUrl } from '@dao-dao/utils'

export type ImageTextDisplayProps = {
  /**
   * Image URL.
   */
  imageUrl: string
  /**
   * Label.
   */
  label: string
  /**
   * Optional caption.
   */
  caption?: string
  /**
   * Optional container class name.
   */
  className?: string
  /**
   * Optional style.
   */
  style?: CSSProperties
}

export const ImageTextDisplay = ({
  imageUrl,
  label,
  caption,
  className,
  style,
}: ImageTextDisplayProps) => {
  return (
    <div
      className={clsx('flex flex-row gap-2 items-center', className)}
      style={style}
    >
      <div
        className={clsx(
          'shrink-0 rounded-full bg-cover bg-center',
          caption ? 'h-7 w-7' : 'h-5 w-5'
        )}
        style={{
          backgroundImage: `url(${toAccessibleImageUrl(imageUrl)})`,
        }}
      ></div>

      <div className="flex flex-col">
        <p className="body-text">{label}</p>
        {caption && <p className="caption-text">{caption}</p>}
      </div>
    </div>
  )
}

export type TokenDisplayProps = Omit<
  ImageTextDisplayProps,
  'imageUrl' | 'label'
> & {
  /**
   * Generic token.
   */
  token: GenericToken
}

export const TokenDisplay = ({ token, ...props }: TokenDisplayProps) => {
  return (
    <ImageTextDisplay
      imageUrl={token.imageUrl || getFallbackImage(token.denomOrAddress)}
      label={token.symbol}
      {...props}
    />
  )
}

export type OperatorDisplayProps = Omit<
  ImageTextDisplayProps,
  'imageUrl' | 'label'
> & {
  /**
   * Operator.
   */
  operator: AvsOperatorInfo
}

export const OperatorDisplay = ({
  operator,
  ...props
}: OperatorDisplayProps) => {
  return (
    <ImageTextDisplay
      imageUrl={operator.imageUrl || getFallbackImage(operator.address)}
      label={operator.name}
      {...props}
    />
  )
}

export type AvsDisplayProps = Omit<
  ImageTextDisplayProps,
  'imageUrl' | 'label'
> & {
  /**
   * Wasmatic app.
   */
  app: WasmaticApp
}

export const AvsDisplay = ({ app, ...props }: AvsDisplayProps) => {
  return (
    <ImageTextDisplay
      imageUrl={app.imageUrl || getFallbackImage(app.name)}
      label={app.name}
      {...props}
    />
  )
}
