import { SigningStargateClient } from '@cosmjs/stargate'

import { HugeDecimal } from '@dao-dao/math'

import { getNativeTokenForChainId, mustGetSupportedChainConfig } from './chain'
import { SupportedSigningCosmWasmClient } from './contracts'

/**
 * Tap faucet for test tokens.
 */
export const tapFaucet = async ({
  chainId,
  address,
  denom,
}: {
  chainId: string
  address: string
  denom: string
}) => {
  const { faucetUrl } = mustGetSupportedChainConfig(chainId)
  if (!faucetUrl) {
    throw new Error('Faucet URL not defined for this chain')
  }

  const res = await fetch(faucetUrl + '/credit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      denom,
      address,
    }),
  })

  if (!res.ok) {
    throw new Error(
      `Faucet request failed: [${res.status} ${res.statusText}] ${await res
        .text()
        .catch(() => '')}`.trim()
    )
  }
}

/**
 * Tap faucet for test tokens if needed.
 */
export const tapFaucetIfNeeded = async ({
  chainId,
  address,
  client,
  minAmount = 1,
}: {
  /**
   * Chain ID.
   */
  chainId: string
  /**
   * Wallet address.
   */
  address: string
  /**
   * Signing client.
   */
  client: SigningStargateClient | SupportedSigningCosmWasmClient
  /**
   * Minimum amount of tokens to have. Defaults to 1.
   */
  minAmount?: number
}) => {
  const { denomOrAddress, decimals } = getNativeTokenForChainId(chainId)
  const { amount } = await client.getBalance(address, denomOrAddress)

  if (HugeDecimal.fromHumanReadable(minAmount, decimals).gt(amount)) {
    await tapFaucet({
      chainId,
      address,
      denom: denomOrAddress,
    })
  }
}
