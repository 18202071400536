/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { UseQueryOptions } from '@tanstack/react-query'

import {
  NullableBoolean,
  String,
  Uint64,
} from '@dao-dao/types/contracts/AiBouncerCaller'
import { getCosmWasmClientForChainId } from '@dao-dao/utils'

import { AiBouncerCallerQueryClient } from '../../../contracts/AiBouncerCaller'

export const aiBouncerCallerQueryKeys = {
  contract: [
    {
      contract: 'aiBouncerCaller',
    },
  ] as const,
  address: (chainId: string, contractAddress: string) =>
    [
      {
        ...aiBouncerCallerQueryKeys.contract[0],
        chainId,
        address: contractAddress,
      },
    ] as const,
  decision: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...aiBouncerCallerQueryKeys.address(chainId, contractAddress)[0],
        method: 'decision',
        args,
      },
    ] as const,
  dao: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...aiBouncerCallerQueryKeys.address(chainId, contractAddress)[0],
        method: 'dao',
        args,
      },
    ] as const,
  cw4Group: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...aiBouncerCallerQueryKeys.address(chainId, contractAddress)[0],
        method: 'cw4_group',
        args,
      },
    ] as const,
  taskQueue: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...aiBouncerCallerQueryKeys.address(chainId, contractAddress)[0],
        method: 'task_queue',
        args,
      },
    ] as const,
  newMemberWeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...aiBouncerCallerQueryKeys.address(chainId, contractAddress)[0],
        method: 'new_member_weight',
        args,
      },
    ] as const,
}
export const aiBouncerCallerQueries = {
  decision: <TData = NullableBoolean>({
    chainId,
    contractAddress,
    args,
    options,
  }: AiBouncerCallerDecisionQuery<TData>): UseQueryOptions<
    NullableBoolean,
    Error,
    TData
  > => ({
    queryKey: aiBouncerCallerQueryKeys.decision(chainId, contractAddress, args),
    queryFn: async () => {
      return new AiBouncerCallerQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).decision({
        address: args.address,
      })
    },
    ...options,
  }),
  dao: <TData = String>({
    chainId,
    contractAddress,
    options,
  }: AiBouncerCallerDaoQuery<TData>): UseQueryOptions<
    String,
    Error,
    TData
  > => ({
    queryKey: aiBouncerCallerQueryKeys.dao(chainId, contractAddress),
    queryFn: async () => {
      return new AiBouncerCallerQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).dao()
    },
    ...options,
  }),
  cw4Group: <TData = String>({
    chainId,
    contractAddress,
    options,
  }: AiBouncerCallerCw4GroupQuery<TData>): UseQueryOptions<
    String,
    Error,
    TData
  > => ({
    queryKey: aiBouncerCallerQueryKeys.cw4Group(chainId, contractAddress),
    queryFn: async () => {
      return new AiBouncerCallerQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).cw4Group()
    },
    ...options,
  }),
  taskQueue: <TData = String>({
    chainId,
    contractAddress,
    options,
  }: AiBouncerCallerTaskQueueQuery<TData>): UseQueryOptions<
    String,
    Error,
    TData
  > => ({
    queryKey: aiBouncerCallerQueryKeys.taskQueue(chainId, contractAddress),
    queryFn: async () => {
      return new AiBouncerCallerQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).taskQueue()
    },
    ...options,
  }),
  newMemberWeight: <TData = Uint64>({
    chainId,
    contractAddress,
    options,
  }: AiBouncerCallerNewMemberWeightQuery<TData>): UseQueryOptions<
    Uint64,
    Error,
    TData
  > => ({
    queryKey: aiBouncerCallerQueryKeys.newMemberWeight(
      chainId,
      contractAddress
    ),
    queryFn: async () => {
      return new AiBouncerCallerQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).newMemberWeight()
    },
    ...options,
  }),
}
export interface AiBouncerCallerReactQuery<TResponse, TData = TResponse> {
  chainId: string
  contractAddress: string
  options?: Omit<
    UseQueryOptions<TResponse, Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: undefined
  }
}
export interface AiBouncerCallerNewMemberWeightQuery<TData>
  extends AiBouncerCallerReactQuery<Uint64, TData> {}
export interface AiBouncerCallerTaskQueueQuery<TData>
  extends AiBouncerCallerReactQuery<String, TData> {}
export interface AiBouncerCallerCw4GroupQuery<TData>
  extends AiBouncerCallerReactQuery<String, TData> {}
export interface AiBouncerCallerDaoQuery<TData>
  extends AiBouncerCallerReactQuery<String, TData> {}
export interface AiBouncerCallerDecisionQuery<TData>
  extends AiBouncerCallerReactQuery<NullableBoolean, TData> {
  args: {
    address: string
  }
}
