import { AddCircleOutlined } from '@mui/icons-material'
import { useForm } from 'react-hook-form'

import { HugeDecimal } from '@dao-dao/math'
import {
  GenericToken,
  LoadingData,
  NewCommitmentForm,
  StatelessNewCommitmentCardProps,
} from '@dao-dao/types'
import { tokensEqual, validatePositive, validateRequired } from '@dao-dao/utils'

import { Button } from '../buttons'
import { ErrorPage } from '../error'
import {
  InputErrorMessage,
  InputLabel,
  NumericInput,
  TokenInput,
} from '../inputs'
import { LayerCard, LayerInfoList } from '../layer'
import { TokenAmountDisplay } from '../token'
import { AvsOperatorSelector } from './AvsOperatorSelector'
import { AvsSelector } from './AvsSelector'

export const NewCommitmentCard = ({
  assets,
  apps,
  operators,
  onSubmit,
  onClose,
  className,
}: StatelessNewCommitmentCardProps) => {
  const tokens: LoadingData<GenericToken[]> =
    assets.loading || assets.errored
      ? { loading: true }
      : { loading: false, data: assets.data.map(({ token }) => token) }

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<NewCommitmentForm>()

  const selectedToken: GenericToken | undefined = watch('token')
  const selectedBalance =
    assets.loading || assets.errored || !selectedToken
      ? undefined
      : assets.data.find(({ token }) => tokensEqual(token, selectedToken))
          ?.balance

  const selectedAppName: string | undefined = watch('app')
  const selectedApp =
    apps.loading || apps.errored
      ? undefined
      : apps.data.find((app) => app.name === selectedAppName)

  const selectedOperatorAddress = watch('operator')
  const selectedOperator =
    operators.loading || operators.errored
      ? undefined
      : operators.data.find(
          (operator) => operator.address === selectedOperatorAddress
        )

  return (
    <LayerCard
      Icon={AddCircleOutlined}
      className={className}
      onClose={onClose}
      title="New Commitment"
    >
      {assets.errored ? (
        <ErrorPage error={assets.error} />
      ) : apps.errored ? (
        <ErrorPage error={apps.error} />
      ) : (
        <form className="flex flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
          <div className="p-3 flex flex-col gap-4 rounded-md border border-border-secondary">
            <div className="flex flex-col gap-1">
              <InputLabel name="Asset" />
              <TokenInput
                onSelectToken={(token) => setValue('token', token)}
                selectedToken={selectedToken}
                tokens={tokens}
              />
              {selectedBalance && (
                <div className="flex flex-row gap-2 items-center mt-1">
                  <p className="caption-text">Balance:</p>
                  <TokenAmountDisplay
                    amount={HugeDecimal.from(selectedBalance)}
                    decimals={selectedToken.decimals}
                    iconUrl={selectedToken.imageUrl}
                    symbol={selectedToken.symbol}
                  />
                </div>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <InputLabel name="Amount" />
              <NumericInput
                fieldName="amount"
                getValues={getValues}
                max={
                  selectedBalance &&
                  HugeDecimal.from(selectedBalance).toHumanReadableString(
                    selectedToken.decimals
                  )
                }
                min={HugeDecimal.one.toHumanReadableNumber(
                  selectedToken?.decimals || 0
                )}
                setValue={setValue}
                step={HugeDecimal.one.toHumanReadableNumber(
                  selectedToken?.decimals || 0
                )}
                validation={[validateRequired, validatePositive]}
              />
              <InputErrorMessage error={errors.amount} />
            </div>
          </div>

          <div className="p-3 flex flex-col gap-4 rounded-md border border-border-secondary">
            <div className="flex flex-col gap-1">
              <InputLabel name="AVS" />
              <AvsSelector
                apps={apps}
                onSelect={(app) => setValue('app', app.name)}
                selectedApp={selectedApp}
              />
            </div>

            <div className="flex flex-col gap-1">
              <InputLabel name="Operator" />
              <AvsOperatorSelector
                onSelect={(operator) => setValue('operator', operator.address)}
                operators={operators}
                selectedOperator={selectedOperator}
              />
            </div>
          </div>

          <div className="p-3 bg-background-secondary border border-border-secondary rounded-md flex flex-col gap-2">
            <p className="primary-text">Terms</p>

            <LayerInfoList
              items={[
                {
                  label: 'Estimated APR',
                  tooltip:
                    'How much yield you will earn by making this commitment.',
                  value: '12.6%',
                  valueClassName: 'text-text-interactive-valid',
                },
                {
                  label: 'Slashing',
                  tooltip:
                    'The percent of this commitment at risk of being slashed.',
                  value: '5.00%',
                  valueClassName: 'text-text-interactive-error',
                },
                {
                  label: 'Unboding period',
                  tooltip:
                    'How long it will take to unbond this commitment in the future.',
                  value: '2 weeks',
                },
              ]}
            />
          </div>

          <Button center size="lg" type="submit" variant="brand">
            Commit
          </Button>
        </form>
      )}
    </LayerCard>
  )
}
