/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { Coin, StdFee } from '@cosmjs/amino'
import {
  CosmWasmClient,
  ExecuteResult,
  SigningCosmWasmClient,
} from '@cosmjs/cosmwasm-stargate'

import {
  ExecuteMsg,
  NullableBoolean,
  String,
  TaskHookExecuteMsg,
  Uint64,
} from '@dao-dao/types/contracts/AiBouncerCaller'
import { CHAIN_GAS_MULTIPLIER } from '@dao-dao/utils'

export interface AiBouncerCallerReadOnlyInterface {
  contractAddress: string
  decision: ({ address }: { address: string }) => Promise<NullableBoolean>
  dao: () => Promise<String>
  cw4Group: () => Promise<String>
  taskQueue: () => Promise<String>
  newMemberWeight: () => Promise<Uint64>
}
export class AiBouncerCallerQueryClient
  implements AiBouncerCallerReadOnlyInterface
{
  client: CosmWasmClient
  contractAddress: string
  constructor(client: CosmWasmClient, contractAddress: string) {
    this.client = client
    this.contractAddress = contractAddress
    this.decision = this.decision.bind(this)
    this.dao = this.dao.bind(this)
    this.cw4Group = this.cw4Group.bind(this)
    this.taskQueue = this.taskQueue.bind(this)
    this.newMemberWeight = this.newMemberWeight.bind(this)
  }
  decision = async ({
    address,
  }: {
    address: string
  }): Promise<NullableBoolean> => {
    return this.client.queryContractSmart(this.contractAddress, {
      decision: {
        address,
      },
    })
  }
  dao = async (): Promise<String> => {
    return this.client.queryContractSmart(this.contractAddress, {
      dao: {},
    })
  }
  cw4Group = async (): Promise<String> => {
    return this.client.queryContractSmart(this.contractAddress, {
      cw4_group: {},
    })
  }
  taskQueue = async (): Promise<String> => {
    return this.client.queryContractSmart(this.contractAddress, {
      task_queue: {},
    })
  }
  newMemberWeight = async (): Promise<Uint64> => {
    return this.client.queryContractSmart(this.contractAddress, {
      new_member_weight: {},
    })
  }
}
export interface AiBouncerCallerInterface
  extends AiBouncerCallerReadOnlyInterface {
  contractAddress: string
  sender: string
  trigger: (
    {
      message,
      messageId,
      sessionId,
    }: {
      message: string
      messageId: number
      sessionId: string
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  unregister: (
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  updateDao: (
    {
      dao,
    }: {
      dao: string
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  updateCw4Group: (
    {
      cw4Group,
    }: {
      cw4Group: string
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  updateTaskQueue: (
    {
      taskQueue,
    }: {
      taskQueue: string
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  updateNewMemberWeight: (
    {
      weight,
    }: {
      weight: number
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  executeCw4Group: (
    executeMsg: ExecuteMsg,
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  taskHook: (
    taskHookExecuteMsg: TaskHookExecuteMsg,
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
}
export class AiBouncerCallerClient
  extends AiBouncerCallerQueryClient
  implements AiBouncerCallerInterface
{
  client: SigningCosmWasmClient
  sender: string
  contractAddress: string
  constructor(
    client: SigningCosmWasmClient,
    sender: string,
    contractAddress: string
  ) {
    super(client, contractAddress)
    this.client = client
    this.sender = sender
    this.contractAddress = contractAddress
    this.trigger = this.trigger.bind(this)
    this.unregister = this.unregister.bind(this)
    this.updateDao = this.updateDao.bind(this)
    this.updateCw4Group = this.updateCw4Group.bind(this)
    this.updateTaskQueue = this.updateTaskQueue.bind(this)
    this.updateNewMemberWeight = this.updateNewMemberWeight.bind(this)
    this.executeCw4Group = this.executeCw4Group.bind(this)
    this.taskHook = this.taskHook.bind(this)
  }
  trigger = async (
    {
      message,
      messageId,
      sessionId,
    }: {
      message: string
      messageId: number
      sessionId: string
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        trigger: {
          message,
          message_id: messageId,
          session_id: sessionId,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  unregister = async (
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        unregister: {},
      },
      fee,
      memo,
      _funds
    )
  }
  updateDao = async (
    {
      dao,
    }: {
      dao: string
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        update_dao: {
          dao,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  updateCw4Group = async (
    {
      cw4Group,
    }: {
      cw4Group: string
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        update_cw4_group: {
          cw4_group: cw4Group,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  updateTaskQueue = async (
    {
      taskQueue,
    }: {
      taskQueue: string
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        update_task_queue: {
          task_queue: taskQueue,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  updateNewMemberWeight = async (
    {
      weight,
    }: {
      weight: number
    },
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        update_new_member_weight: {
          weight,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  executeCw4Group = async (
    executeMsg: ExecuteMsg,
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        cw4_group: executeMsg,
      },
      fee,
      memo,
      _funds
    )
  }
  taskHook = async (
    taskHookExecuteMsg: TaskHookExecuteMsg,
    fee: number | StdFee | 'auto' = CHAIN_GAS_MULTIPLIER,
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        task_hook: taskHookExecuteMsg,
      },
      fee,
      memo,
      _funds
    )
  }
}
