import {
  ArrowOutwardRounded,
  Code,
  Public,
  Telegram,
  Twitter,
} from '@mui/icons-material'
import { ComponentType } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonLink } from '../buttons'
import { GithubIcon } from '../icons'

export const Footer = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-1 text-text-tertiary">
      <FooterItem
        Icon={Public}
        href="https://layer.xyz"
        name={t('title.website')}
      />
      <FooterItem
        Icon={Code}
        href="https://docs.layer.xyz"
        name={t('title.documentation')}
      />
      <FooterItem
        Icon={Twitter}
        href="https://x.com/LayerOnEth"
        name={t('title.twitter')}
      />
      <FooterItem
        Icon={Telegram}
        href="https://t.me/layer_xyz"
        name={t('title.telegram')}
      />
      <FooterItem
        Icon={GithubIcon}
        href="https://github.com/Lay3rLabs"
        name={t('title.github')}
      />
    </div>
  )
}

export interface FooterItemProps {
  href: string
  Icon: ComponentType<{ className: string }>
  name: string
}

export const FooterItem = ({ href, Icon, name }: FooterItemProps) => (
  <ButtonLink
    contentContainerClassName="justify-between"
    href={href}
    variant="ghost"
  >
    <div className="flex flex-row items-center gap-2">
      <Icon className="!h-4 !w-4" />
      <p>{name}</p>
    </div>

    <ArrowOutwardRounded className="!h-4 !w-4" />
  </ButtonLink>
)
