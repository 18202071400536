import { SmartToy, SmartToyOutlined } from '@mui/icons-material'

import {
  Widget,
  WidgetId,
  WidgetLocation,
  WidgetVisibilityContext,
} from '@dao-dao/types'
import { ACTIVE_LAYER_CHAIN_ID } from '@dao-dao/utils'

import { Editor } from './Editor'
import { Renderer } from './Renderer'
import { AiBouncerData } from './types'

export const AiBouncerWidget: Widget<AiBouncerData> = {
  id: WidgetId.AiBouncer,
  Icon: SmartToyOutlined,
  IconFilled: SmartToy,
  location: WidgetLocation.Home,
  visibilityContext: WidgetVisibilityContext.Always,
  defaultValues: {
    caller: '',
    requirements: '',
  },
  Renderer,
  Editor,
  isChainSupported: (chainId) => chainId === ACTIVE_LAYER_CHAIN_ID,
}
