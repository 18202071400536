import clsx from 'clsx'

import { StatelessAvsCardProps } from '@dao-dao/types'
import { getAvsPath, getFallbackImage } from '@dao-dao/utils'

import { ErrorPage } from '../error'
import { AvsImage } from './AvsImage'

export const AvsCard = ({
  app: _app,
  LinkWrapper,
  className,
}: StatelessAvsCardProps) => {
  if (_app.loading) {
    return <AvsCardLoader />
  } else if (_app.errored) {
    return <ErrorPage error={_app.error} />
  }

  const { name } = _app.data

  return (
    <LinkWrapper
      className={clsx(
        'bg-background-tertiary hover:bg-background-interactive-hover hover:ring-border-interactive-hover active:bg-background-interactive-pressed active:ring-border-interactive-focus relative gap-2.5 flex min-h-[128px] w-full flex-col items-stretch rounded-md p-4 ring-1 ring-inset ring-border-primary transition-all',
        className
      )}
      href={getAvsPath(name)}
      prefetch
    >
      <div className="flex flex-row items-center gap-2.5">
        <AvsImage imageUrl={getFallbackImage(name)} size={24} />

        <p className="title-text text-center">{name}</p>
      </div>

      <p className="body-text break-words">
        Lorem ipsum dolor sit amet consectetur. Neque imperdiet cras lacus sed
        purus massa arcu.
      </p>
    </LinkWrapper>
  )
}

export const AvsCardLoader = () => (
  <div className="bg-background-secondary h-[128px] w-full animate-pulse rounded-md"></div>
)
