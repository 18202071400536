import { QueryClient, queryOptions } from '@tanstack/react-query'

import {
  WasmaticApp,
  WasmaticGetInfoResponse,
  WasmaticListAppsResponse,
} from '@dao-dao/types'
import {
  mustGetSupportedChainConfig,
  objectMatchesStructure,
  retry,
} from '@dao-dao/utils'

/**
 * List wasmatic operators.
 */
export const listWasmaticOperators = async ({
  chainId,
}: {
  chainId: string
}): Promise<string[]> => {
  const { wasmaticUrl } = mustGetSupportedChainConfig(chainId)

  return await retry(
    3,
    async () => {
      const res = await fetch(wasmaticUrl + '/info')

      if (res.ok) {
        const json: WasmaticGetInfoResponse = await res.json()
        if (
          objectMatchesStructure(json, {
            operators: {},
          })
        ) {
          return json.operators
        } else {
          throw new Error(
            'Invalid wasmatic operators response: ' + JSON.stringify(json)
          )
        }
      }

      throw new Error('Failed to fetch wasmatic operators: ' + res.statusText)
    },
    500
  )
}

/**
 * List wasmatic apps.
 */
export const listWasmaticApps = async (
  queryClient: QueryClient,
  {
    chainId,
  }: {
    chainId: string
  }
): Promise<WasmaticApp[]> => {
  const { wasmaticUrl } = mustGetSupportedChainConfig(chainId)

  return await retry(
    3,
    async () => {
      const res = await fetch(wasmaticUrl + '/app')

      if (res.ok) {
        const json: WasmaticListAppsResponse = await res.json()
        if (
          objectMatchesStructure(json, {
            apps: {},
            digests: {},
          })
        ) {
          // Set digests.
          queryClient.setQueryData(
            wasmaticQueries.listDigests(queryClient, {
              chainId,
            }).queryKey,
            json.digests
          )

          return json.apps
        } else {
          throw new Error(
            'Invalid wasmatic apps response: ' + JSON.stringify(json)
          )
        }
      }

      throw new Error('Failed to fetch wasmatic apps: ' + res.statusText)
    },
    500
  )
}

/**
 * List wasmatic digests.
 */
export const listWasmaticDigests = async (
  queryClient: QueryClient,
  {
    chainId,
  }: {
    chainId: string
  }
): Promise<string[]> => {
  const { wasmaticUrl } = mustGetSupportedChainConfig(chainId)

  return await retry(
    3,
    async () => {
      const res = await fetch(wasmaticUrl + '/app')

      if (res.ok) {
        const json: WasmaticListAppsResponse = await res.json()
        if (
          objectMatchesStructure(json, {
            apps: {},
            digests: {},
          })
        ) {
          // Set apps.
          queryClient.setQueryData(
            wasmaticQueries.listApps(queryClient, {
              chainId,
            }).queryKey,
            json.apps
          )

          return json.digests
        } else {
          throw new Error(
            'Invalid wasmatic digests response: ' + JSON.stringify(json)
          )
        }
      }

      throw new Error('Failed to fetch wasmatic digests: ' + res.statusText)
    },
    500
  )
}

export const wasmaticQueries = {
  /**
   * List wasmatic operators.
   */
  listOperators: (options: Parameters<typeof listWasmaticOperators>[0]) =>
    queryOptions({
      queryKey: ['wasmatic', 'operators', options],
      queryFn: () => listWasmaticOperators(options),
    }),
  /**
   * List wasmatic apps.
   */
  listApps: (
    queryClient: QueryClient,
    options: Parameters<typeof listWasmaticApps>[1]
  ) =>
    queryOptions({
      queryKey: ['wasmatic', 'apps', options],
      queryFn: () => listWasmaticApps(queryClient, options),
    }),
  /**
   * List wasmatic digests.
   */
  listDigests: (
    queryClient: QueryClient,
    options: Parameters<typeof listWasmaticDigests>[1]
  ) =>
    queryOptions({
      queryKey: ['wasmatic', 'digests', options],
      queryFn: () => listWasmaticDigests(queryClient, options),
    }),
}
