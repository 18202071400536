import clsx from 'clsx'

import { AvsImageProps } from '@dao-dao/types'

export const AvsImage = ({ imageUrl, size, className }: AvsImageProps) => {
  return (
    <div
      className={clsx(
        'bg-cover bg-center bg-no-repeat rounded-full overflow-hidden',
        className
      )}
      style={{ backgroundImage: `url(${imageUrl})`, width: size, height: size }}
    ></div>
  )
}
