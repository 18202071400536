import clsx from 'clsx'
import { ComponentType } from 'react'

export type LayerBadgeProps = {
  /**
   * The style.
   */
  style: 'warning' | 'error' | 'valid' | 'brand'
  /**
   * The label.
   */
  label: string
  /**
   * Optionally an icon.
   */
  Icon?: ComponentType<{ className: string }>
}

export const LayerBadge = ({ style, label, Icon }: LayerBadgeProps) => (
  <div
    className={clsx(
      'inline-flex flex-row items-center gap-2 animate-fade-in rounded-md px-1 py-0.5',
      {
        'bg-background-interactive-warning': style === 'warning',
        'bg-background-interactive-error': style === 'error',
        'bg-background-interactive-valid': style === 'valid',
        'bg-background-interactive-active': style === 'brand',
      }
    )}
  >
    {Icon && (
      <Icon
        className={clsx('!w-4 !h-4', {
          'text-icon-interactive-warning-title': style === 'warning',
          'text-icon-interactive-error': style === 'error',
          'text-icon-interactive-valid': style === 'valid',
          'text-icon-interactive-active': style === 'brand',
        })}
      />
    )}

    <p
      className={clsx({
        'text-text-interactive-warning-title': style === 'warning',
        'text-text-interactive-error': style === 'error',
        'text-text-interactive-valid': style === 'valid',
        'text-text-interactive-active': style === 'brand',
      })}
    >
      {label}
    </p>
  </div>
)
