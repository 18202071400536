import { ChevronRight } from '@mui/icons-material'
import clsx from 'clsx'
import { ReactNode, useState } from 'react'

import { Button } from '../buttons'

export type LayerTableProps = {
  /**
   * Columns. The number of columns must match the number of values in each row.
   */
  columns: LayerTableColumn[]
  /**
   * Rows.
   */
  rows: LayerTableRow[]
  /**
   * Optional hover handler. If row is undefined, hovering stopped.
   */
  onHover?: (row?: LayerTableRow) => void
  /**
   * Optional class name.
   */
  className?: string
}

export const LayerTable = ({
  columns,
  rows,
  onHover,
  className,
}: LayerTableProps) => {
  return (
    <div
      className={clsx(
        'bg-background-tertiary border-border-secondary flex flex-col rounded-md border overflow-hidden',
        className
      )}
    >
      {/* Header */}
      <div
        className="grid border-b border-border-primary"
        style={{
          gridTemplateColumns: `repeat(${columns.length}, minmax(0, 1fr))`,
        }}
      >
        {columns.map(({ label }, index) => (
          <p
            key={label + index}
            className={clsx('caption-text py-3 pr-4', index === 0 && 'pl-4')}
          >
            {label}
          </p>
        ))}
      </div>

      {/* Rows */}
      {rows.map((row, index) => (
        <TableRow
          key={row.id}
          columns={columns}
          last={index === rows.length - 1}
          onHover={onHover}
          row={row}
        />
      ))}
    </div>
  )
}

export type LayerTableRow = {
  /**
   * Unique identifier for the row.
   */
  id: string
  /**
   * Values to display in the row. The number of values must match the number
   * of columns in the header.
   */
  values: ReactNode[]
  /**
   * Whether to display a loader instead of the row.
   */
  loading?: boolean
  /**
   * Optionally highlight this row.
   */
  active?: boolean
} & (
  | {
      /**
       * Collapsible details. If defined, the row will be clickable to reveal
       * the details and an arrow icon will be displayed.
       */
      details?: ReactNode
      /**
       * Optional click handler. If defined, the row will be clickable an arrow
       * icon will be displayed.
       */
      onClick?: never
    }
  | {
      /**
       * Collapsible details. If defined, the row will be clickable to reveal
       * the details and an arrow icon will be displayed.
       */
      details?: never
      /**
       * Optional click handler. If defined, the row will be clickable an arrow
       * icon will be displayed.
       */
      onClick?: () => void
    }
)

export type LayerTableColumn = {
  /**
   * Label to display in the header.
   */
  label: string
  /**
   * Class name to apply to the cell content.
   */
  className?: string
}

type TableRowProps = Pick<LayerTableProps, 'onHover'> & {
  /**
   * Columns.
   */
  columns: LayerTableColumn[]
  /**
   * Row to display.
   */
  row: LayerTableRow
  /**
   * Whether this is the last row.
   */
  last: boolean
}

const TableRow = ({ columns, row, last, onHover }: TableRowProps) => {
  const [showDetails, setShowDetails] = useState(false)

  if (row.loading) {
    return <TableRowLoader last={last} />
  }

  const hasAction = !!row.details || !!row.onClick

  return (
    <>
      <Button
        className={clsx(
          'group/row animate-fade-in border-b border-transparent',
          !last &&
            !showDetails &&
            !row.active &&
            '!border-border-secondary group-hover/row:!border-transparent'
        )}
        disabled={!hasAction}
        onClick={
          row.onClick ||
          (row.details ? () => setShowDetails((s) => !s) : undefined)
        }
        onMouseOut={onHover && (() => onHover?.())}
        onMouseOver={onHover && (() => onHover?.(row))}
        pressed={row.active}
        variant="row"
      >
        <div
          className="grid text-left grow self-stretch"
          style={{
            gridTemplateColumns: `repeat(${columns.length}, minmax(0, 1fr))`,
          }}
        >
          {columns.map(({ className }, index) => {
            const value = row.values[index]

            const showArrowOnLastColumn =
              hasAction && index === columns.length - 1

            return (
              <div
                key={row.id + index}
                className={clsx(
                  'p-4 flex flex-row justify-stretch items-center',
                  index > 0 && 'pl-0',
                  showArrowOnLastColumn && 'gap-8 !justify-between',
                  className
                )}
              >
                {typeof value === 'string' ? <p>{value}</p> : value}

                {showArrowOnLastColumn && (
                  <ChevronRight
                    className={clsx(
                      'transition !h-6 !w-6',
                      showDetails
                        ? 'rotate-90 !text-icon-primary'
                        : '!text-icon-tertiary rotate-0 opacity-0 group-hover/row:opacity-100'
                    )}
                  />
                )}
              </div>
            )
          })}
        </div>
      </Button>

      {!!row.details && showDetails && (
        <div
          className={clsx(
            'bg-background-tertiary p-4 pt-2 flex flex-col gap-4',
            !last && 'border-b border-border-primary'
          )}
        >
          {row.details}
        </div>
      )}
    </>
  )
}

const TableRowLoader = ({ last }: { last: boolean }) => (
  <div
    className={clsx(
      'bg-background-secondary animate-pulse h-12',
      !last && 'border-b border-border-secondary'
    )}
  ></div>
)
