import { ArrowBack } from '@mui/icons-material'
import { ComponentType } from 'react'

import {
  ButtonLinkProps,
  StatefulAvsDemoProps,
  WasmaticApp,
} from '@dao-dao/types'
import { getAvsPath } from '@dao-dao/utils'

export type AvsDemoPageProps = {
  /**
   * Wasmatic app.
   */
  app: WasmaticApp
  /**
   * Stateful button link component.
   */
  ButtonLink: ComponentType<ButtonLinkProps>
  /**
   * Stateful demo component.
   */
  AvsDemo: ComponentType<StatefulAvsDemoProps>
}

export const AvsDemoPage = ({ app, ButtonLink, AvsDemo }: AvsDemoPageProps) => {
  return (
    <div className="flex flex-col gap-8">
      <ButtonLink
        containerClassName="self-start"
        href={getAvsPath(app.name)}
        variant="secondary"
      >
        <ArrowBack className="!w-5 !h-5" />
        Back
      </ButtonLink>

      <AvsDemo app={app} />
    </div>
  )
}
