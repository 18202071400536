import { useQueryClient } from '@tanstack/react-query'
import { NextSeo } from 'next-seo'

import { wasmaticQueries } from '@dao-dao/state/query'
import {
  AvsListPage as StatelessAvsListPage,
  useChain,
} from '@dao-dao/stateless'

import { useQueryLoadingDataWithError } from '../../hooks'
import { AvsCard, NewAvsCard } from '../avs'
import { PageHeaderContent } from '../PageHeaderContent'

export const AvsListPage = () => {
  const { chainId } = useChain()

  const queryClient = useQueryClient()
  const apps = useQueryLoadingDataWithError(
    wasmaticQueries.listApps(queryClient, {
      chainId,
    })
  )

  return (
    <>
      <NextSeo
        description="Actively validated services."
        openGraph={{
          title: 'Services',
          description: 'Actively validated services.',
        }}
        title="Services"
      />

      <PageHeaderContent title="Services" />

      <StatelessAvsListPage
        AvsCard={AvsCard}
        NewAvsCard={NewAvsCard}
        apps={apps}
      />
    </>
  )
}
