import { Interests } from '@mui/icons-material'

import { StatelessAvsAssetsProps } from '@dao-dao/types'
import { formatPercentOf100, serializeTokenSource } from '@dao-dao/utils'

import { ErrorPage } from '../error'
import { TokenDisplay } from '../ImageTextDisplay'
import { LayerCard, LayerTable } from '../layer'

export const AvsAssets = ({ assets, className }: StatelessAvsAssetsProps) => {
  return (
    <LayerCard
      Icon={Interests}
      className={className}
      subtitle={
        assets.loading
          ? 'Loading...'
          : assets.errored
          ? undefined
          : `${assets.data.length} assets accepted`
      }
      title="Assets"
    >
      {assets.errored ? (
        <ErrorPage error={assets.error} />
      ) : (
        <LayerTable
          className="grow"
          columns={[
            {
              label: 'Operators',
            },
            {
              label: 'Ratio',
              className: 'text-text-tertiary',
            },
          ]}
          rows={
            assets.loading
              ? [...Array(3)].map((_, index) => ({
                  id: index.toString(),
                  loading: true,
                  values: [],
                }))
              : assets.data.map(({ token, percentOfTotal }) => ({
                  id: serializeTokenSource(token),
                  values: [
                    <TokenDisplay
                      key={serializeTokenSource(token)}
                      token={token}
                    />,
                    formatPercentOf100(percentOfTotal),
                  ],
                }))
          }
        />
      )}
    </LayerCard>
  )
}
