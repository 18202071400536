import clsx from 'clsx'
import { ReactNode } from 'react'

import { TooltipInfoIcon } from '../tooltip'

export type LayerInfoListProps = {
  /**
   * List of items to display.
   */
  items: {
    /**
     * Item label.
     */
    label: string
    /**
     * Optional label tooltip.
     */
    tooltip?: string
    /**
     * Item value.
     */
    value: ReactNode
    /**
     * Value container class name.
     */
    valueClassName?: string
  }[]
  /**
   * Optional container class name.
   */
  className?: string
}

export const LayerInfoList = ({ items, className }: LayerInfoListProps) => {
  return (
    <div className={clsx('flex flex-col', className)}>
      {items.map(({ label, tooltip, value, valueClassName }, index) => (
        <div
          key={label + index}
          className="flex flex-row justify-between items-center border-b py-2 border-border-secondary border-dashed gap-8"
        >
          <div className="flex flex-row items-center gap-1">
            <p className="body-text text-text-tertiary">{label}</p>
            {tooltip && <TooltipInfoIcon size="sm" title={tooltip} />}
          </div>

          <div
            className={clsx(
              'body-text flex flex-row justify-end text-right font-semibold',
              valueClassName
            )}
          >
            {typeof value === 'string' ? <p>{value}</p> : value}
          </div>
        </div>
      ))}
    </div>
  )
}
